import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API_FUNCTIONS, API_STORAGE } from '../utils/apiFunctions'
import { GlobalContext } from '../contexts/GlobalContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretLeft } from '@fortawesome/free-regular-svg-icons'
import ProjectCard from '../components/ProjectCard'

import  {format} from 'date-fns'
import { fr, enUS } from 'date-fns/locale'
import { faLink, faSpinner } from '@fortawesome/free-solid-svg-icons'
import github from '../assets/icons/github-large.png'
import { LinkedinShareButton } from 'react-share'
import linkedin from '../assets/icons/linkedin-large.png'
import { Helmet } from 'react-helmet'
import Carousel from '../components/Carousel'

function Project() {
  const navigate = useNavigate()
  const {projectSlug} = useParams()

  const {language} = useContext(GlobalContext)

  const [isLoaded, setIsLoaded] = useState(false)
  const [project, setProject] = useState(null)
  const [allProjects, setAllProjects] = useState([])
  const [isCarouselOpened, setIsCarouselOpened] = useState(false)

  const loadingProject = async() => {
    try{
      const [currentProjectDatas, projectDatas] = await Promise.all([
        API_FUNCTIONS.oneProjectBySlug(projectSlug),
        API_FUNCTIONS.allProjects()
      ])

      if(currentProjectDatas.status === 200){
        setProject(currentProjectDatas.data.data)
      }else{
        navigate('/')
      }

      if(projectDatas.status === 200){
        setAllProjects(projectDatas.data.data.filter(item => item.id !== currentProjectDatas.data.data.id))
      }
    }catch(e){
      console.error(e)
    }finally{
      setIsLoaded(true)
    }
  }

  useEffect(() => {
    loadingProject()
  }, [projectSlug])

  const formatDate = (initialDate) => {
    const formatedDate = new Date(initialDate)
    return format(formatedDate, 'MMMM yyyy', {locale: language === 'fr' ? fr : enUS})
  }

  const handleCloseCarousel = () => {
    setIsCarouselOpened(false)
  }


  return (
    isLoaded ? 
    <div className='mainContainer projectPage'>
      <Helmet>
        <title>Yann Ghistelinck - {project.project_name}</title>
        <meta name="description" content={project.project_fr_synopsis} />
        <meta name="keywords" content={project.skills.map(skill => skill.skill_fr_name)} />

        <meta property="og:title" content={project.project_name} />
        <meta property="og:description" content={project.project_fr_synopsis} />
        <meta property="og:image" content={API_STORAGE+project.cover_urls['medium-jpg']} />
        <meta property="og:url" content={'https://yann-ghistelinck.fr/projects/'+project.project_slug} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={project.project_name} />
        <meta name="twitter:description" content={project.project_fr_synopsis} />
        <meta name="twitter:image" content={API_STORAGE+project.cover_urls['thumb-webp']} />
      </Helmet>


        <FontAwesomeIcon icon={faSquareCaretLeft} onClick={() => navigate(language === 'fr' ? '/projets' : '/projects')} className='projectPage__return'/>
        <h1 className='projectPage__title'>{project.project_name}</h1>
        {/* <span className='projectPage__date'>{project.project_date}</span> */}
        <span className='projectPage__date'>{formatDate(project.project_date)}</span>


        <div className='projectPage__skills'>
          {project.skills.map(skill => 
            <div className='projectPage__skills__item'>{language === 'fr' ? skill.skill_fr_name : skill.skill_en_name}</div>
          )}
        </div>
        
        <p className='projectPage__synopsis'>{language === 'fr' ? project.project_fr_synopsis : project.project_en_synopsis}</p>

        <div className='projectPage__carrousel'>
          <picture>
            <source 
              srcSet={`
                ${API_STORAGE + project.cover_urls['small-webp']} 680w,
                ${API_STORAGE + project.cover_urls['medium-webp']} 990w,
                ${API_STORAGE + project.cover_urls['large-webp']} 1200w
              `}
              sizes="(max-width: 680px) 100vw,
                    (max-width: 990px) 100%,
                    75vw"
              type="image/webp"
            />

            <source 
              srcSet={`
                ${API_STORAGE + project.cover_urls['small-jpg']} 680w,
                ${API_STORAGE + project.cover_urls['medium-jpg']} 990w,
                ${API_STORAGE + project.cover_urls['large-jpg']} 1200w
              `}
              sizes="(max-width: 680px)100vw,
                    (max-width: 990px)100%,
                    75vw"
              type="image/jpeg"
            />
            
            <img 
              src={API_STORAGE + project.cover_urls['large-webp']} 
              alt={language === 'fr' ? 'Couverture du projet' : 'Project cover'}
            />
          </picture>
        </div>

        <div className='projectPage__network'> 

          { 
            project.project_link && 
              <a href={project.project_link} target="blank" className='projectPage__network__link'>
                <FontAwesomeIcon icon={faLink}/>
                {language === 'fr' ? 'Visiter le projet' : 'Visit project'}
            </a>
          }
          {
            project.project_github &&
              <a href={project.project_github} target='blank' className='projectPage__network__github'>
                <img src={github} alt={language === 'fr' ? 'logo github' : 'Github icon'}/>
              </a>
           } 
          <LinkedinShareButton
            // url={'https://yann-ghistelinck.fr/projects/'+project.project_slug}
            url={window.location.href}
            title={project.project_name}
            summary={language === 'fr' ? project.project_fr_synopsis : project.project_en_synopsis}
            // source={API_STORAGE + project.cover_urls['small-webp']}
            source={'https://yann-ghistelinck.fr/projects/'+project.project_slug}
          >
            <div className='projectPage__network__linkedin'>
              {language === 'fr' ? 'Partager sur ' : 'Share on '} <img src={linkedin} alt={language === 'fr' ? 'Partager sur Linkedin' : 'Share on Linkedin'}/>
            </div>
          </LinkedinShareButton>

        </div>

        <article>
          <div className="projectPage__content" dangerouslySetInnerHTML={{ __html: language === 'fr' ? project.project_fr_description : project.project_en_description }}></div>

          <div className='projectPage__imagesGroup' onClick={() => setIsCarouselOpened(true)}>
            {project.images.sort((a, b) => a.image_index - b.image_index).slice(0,5).sort((a, b) => b.image_index - a.image_index).map((image, index) => 
              <div className='projectPage__imagesGroup__item' key={index}>

                <picture>
                  <source 
                    srcSet={`
                      ${API_STORAGE + image.urls['small-webp']} 680w,
                      ${API_STORAGE + image.urls['medium-webp']} 990w,
                      ${API_STORAGE + image.urls['large-webp']} 1200w
                      `}
                    sizes="(max-width: 680px) 200px,
                    (max-width: 990px) 200px,
                          200px"
                    type="image/webp"
                  />
    
                  <source 
                    srcSet={`
                      ${API_STORAGE + image.urls['small-jpg']} 680w,
                      ${API_STORAGE + image.urls['medium-jpg']} 990w,
                      ${API_STORAGE + image.urls['large-jpg']} 1200w
                      `}
                    sizes="(max-width: 680px)200px,
                    (max-width: 990px)200px,
                    200px"
                    type="image/jpeg"
                  />
                
                  <img 
                    src={API_STORAGE + image.urls['large-webp']} 
                    alt={language === 'fr' ? image.image_fr_alt : image.image_en_alt}
                  />
                </picture>
              </div>
            )}
          </div>
          {/* <span className='projectPage__imagesGroup__name'>{language === 'fr' ? 'Voir en images' : ''}</span> */}

        </article>

        {allProjects.length > 0 && <span className='projectPage__recommendations__title'>{language === 'fr' ? 'Recommandations' : 'Recommendations'}</span>}
        {allProjects &&
          <div className='projectPage__recommendations__container'>
            {allProjects.filter(item => item.id !== project.id).sort((a, b) => new Date(b.project_date) - new Date(a.project_date)).slice(0,3).map(p => 
              <ProjectCard project={p} key={p.id}/>
            )}
          </div>
        }

        {isCarouselOpened && <Carousel images={project.images} handleCloseCarousel={handleCloseCarousel}/>}

    </div>

    :

    <div className='mainContainer projectPage__loader'>
      <FontAwesomeIcon icon={faSpinner} className='spinner'/>
    </div>
  )
}

export default Project