import React, {useRef, useEffect} from 'react'

import ScrollReveal from 'scrollreveal'

const ScrollEffect = ({ children }) => {
    const sectionRef = useRef();
    useEffect(() => {
      if (sectionRef.current)
        ScrollReveal().reveal(sectionRef.current, {
          reset: true,
          delay: 150
        });
    }, []);
  
    return (
      <section ref={sectionRef}>
        {children}
      </section>
    );
  };

export default ScrollEffect