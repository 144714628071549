import axios from "axios";

// export const API_URL = "http://127.0.0.1:8000/api"
export const API_URL = "https://api.yann-ghistelinck.fr/api"

// export const API_STORAGE = "http://127.0.0.1:8000/storage/"
export const API_STORAGE = "https://api.yann-ghistelinck.fr/storage/"

export const API_ROUTES={
    LOGIN: `${API_URL}/login`,
    LOGOUT: `${API_URL}/logout`,
    CURRENT_USER: `${API_URL}/currentUser`,
    UPDATE_PASSWORD: `${API_URL}/updatePassword`,
    CONTACT: `${API_URL}/contact`,
    SKILL_CATEGORIES: `${API_URL}/skill_categories`,
    SKILLS: `${API_URL}/skills`,
    PROJECTS: `${API_URL}/projects`,
    PROJECT_SKILL: `${API_URL}/project_skill`,
    ATTACH_PROJECT_SKILL: `${API_URL}/attach_project_skill`,
    DETACH_PROJECT_SKILL: `${API_URL}/detach_project_skill`,
    PROJECTS_BY_SLUG: `${API_URL}/project_by_slug`,
    PUBLISH_PROJECT: `${API_URL}/publish_project`,
    REPLACE_PROJECT_COVER: `${API_URL}/replace_project_cover`,
    UNPUBLISH_PROJECT: `${API_URL}/unpublish_project`,
    IMAGES: `${API_URL}/images`,
}

export const API_FUNCTIONS={
    // USERS
    login: async(data)=>{
        try{
            return await axios.post(API_ROUTES.LOGIN, data)

        }catch(e){
            return e
        }
    },
    logout: async(token)=>{
        try{
            return await axios.post(API_ROUTES.LOGOUT, null,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    currentUser: async(token)=>{
        try{
            return await axios.get(API_ROUTES.CURRENT_USER, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updatePassword: async(request, token)=>{
        try{    
            return await axios.put(API_ROUTES.UPDATE_PASSWORD+'/1', request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    /**
     *  CONTACT
     */
    contact: async($request)=>{
        try{
            return await axios.post(API_ROUTES.CONTACT, $request)
        }catch(e){
            return e
        }
    },
    /**
     *  SKILL CATEGORIES
     */
    allSkillCategories: async()=>{
        try{
            return await axios.get(API_ROUTES.SKILL_CATEGORIES)
        }catch(e){
            return e
        }
    },
    addSkillCategory: async(request, token)=>{
        try{
            return await axios.post(API_ROUTES.SKILL_CATEGORIES, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updateSkillCategory: async(request, token)=>{
        try{
            return await axios.put(API_ROUTES.SKILL_CATEGORIES+'/'+request.id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteSkillCategory: async(id, token)=>{
        try{
            return await axios.delete(API_ROUTES.SKILL_CATEGORIES+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },

    /**
     *  SKILLS
     */
    allSkills: async()=>{
        try{
            return await axios.get(API_ROUTES.SKILLS)
        }catch(e){
            return e
        }
    },
    addSkill: async(request, token) => {
        try{
            return await axios.post(API_ROUTES.SKILLS, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updateSkill: async(request, token) => {
        try{
            return await axios.put(API_ROUTES.SKILLS+'/'+request.id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteSkill: async(id, token) => {
        try{
            return await axios.delete(API_ROUTES.SKILLS+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    /**
     *  PROJECTS
     */
    allProjects: async($request) => {
        try{
            return await axios.get(API_ROUTES.PROJECTS, {
                params: {
                    request: $request
                }
            })
        }catch(e){
            return e
        }
    },
    oneProjectBySlug: async(slug)=>{
        try{
            return await axios.get(API_ROUTES.PROJECTS_BY_SLUG, {
                params: {
                    slug: slug
                }
            })
        }catch(e){
            return e
        }
    },
    addProject: async(request, token)=>{
        try{
            return await axios.post(API_ROUTES.PROJECTS, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    publishProject: async(id, token) => {
        try{
            return await axios.put(API_ROUTES.PUBLISH_PROJECT+'/'+id, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    unpublishProject: async(id, token) => {
        try{
            return await axios.put(API_ROUTES.UNPUBLISH_PROJECT+'/'+id, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updateProject: async(request, token) => {
        try{
            return await axios.put(API_ROUTES.PROJECTS+'/'+request.id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteProject: async(id, token) => {
        try{    
            return await axios.delete(API_ROUTES.PROJECTS+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    attachProjectSkill: async(projectId, request, token) => {
        try{
            return await axios.put(API_ROUTES.ATTACH_PROJECT_SKILL+'/'+projectId, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    detachProjectSkill: async(projectId, request, token) => {
        try{
            return await axios.put(API_ROUTES.DETACH_PROJECT_SKILL+'/'+projectId, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    projectToggleFavorite: async(id, token)=>{
        try{
            return await axios.put(API_URL+'/project_toggle_favorite/'+id, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    replaceProjectCover: async(request, id, token)=>{
        try{
            return await axios.post(API_ROUTES.REPLACE_PROJECT_COVER+'/'+id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    /**
     *  PROJECT SKILL
     */
    updateProjectSkill: async(id, token)=>{
        try{
            return await axios.put(API_ROUTES.PROJECT_SKILL+'/'+id, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    /**
     *  IMAGES
     */
    addImages: async(request, token) => {
        try{
            return await axios.post(API_ROUTES.IMAGES, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updateImage: async(request, token) => {
        try{
            return await axios.put(API_ROUTES.IMAGES+'/'+request.id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteImage: async(id, token) => {
        try{
            return await axios.delete(API_ROUTES.IMAGES+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    }
}