import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Separator from './Separator'
import { useTranslation } from 'react-i18next'

function Menu() {
    const [t] = useTranslation('global')

    const [isMenuOpened, setIsMenuOpened] = useState(false)

    useEffect(() => {
        if(isMenuOpened){
            document.getElementById('menuBtn').classList.add('menu__btn--opened')
            // document.getElementById('menuContent').style.visibility = 'visible'
            // document.getElementById('menuContent').style.width = '70%'
            // document.getElementById('menuContent').style.opacity = '1'
            document.getElementById('menuContent').style.top = '0px'
        }else{
            document.getElementById('menuBtn').classList.remove('menu__btn--opened')
            // document.getElementById('menuContent').style.visibility = 'collapse'
            // document.getElementById('menuContent').style.width = '0'
            // document.getElementById('menuContent').style.opacity = '0'
            document.getElementById('menuContent').style.top = '-100px'
        }
    }, [isMenuOpened])

  return (
    <div className='menu'>
        <button className='menu__btn' onClick={() => setIsMenuOpened(isMenuOpened ? false : true)} id="menuBtn">
            <svg className="menu__btn__item menu__btn__item--1" width="30" height="30" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
            <svg className="menu__btn__item menu__btn__item--2" width="30" height="30" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
            <svg className="menu__btn__item menu__btn__item--3" width="30" height="30" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </button>
        <nav className='menu__container' id="menuContent">
            <a href="#welcome">{t('menu.home')}</a>
            <Separator/>
            <a href="#projects">{t('menu.projects')}</a>
            <Separator/>
            <a href="#skills">{t('menu.skills')}</a>
            <Separator/>
            {/* <Link>{t('menu.about')}</Link>
            <Separator/>
            <Link>{t('menu.formations')}</Link>
            <Separator/> */}
            <a href="#contact">{t('menu.contact')}</a>
        </nav>
    </div>
  )
}

export default Menu