import React, {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import {useForm} from 'react-hook-form'

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalContext } from '../contexts/GlobalContext';
import { API_FUNCTIONS } from '../utils/apiFunctions';

import { TOASTS } from '../utils/toasts'



function Contact() {
    const [t] = useTranslation("global")

    const {theme} = useContext(GlobalContext)
    const {register, handleSubmit, reset, formState: {errors}} = useForm()
    
    const [sending, setSending] = useState(false)

    const sendEmail = async (data) =>{
      setSending(true)

      try{
        const response = await API_FUNCTIONS.contact(data)

        if(response.status === 200){
          TOASTS.success(t('contact.notificationSuccess'))
          reset()
        }else{
          TOASTS.error(t('contact.notificationError'))
        }
      }catch(e){
        TOASTS.error(t('contact.notificationError'))
      }finally{
        setSending(false)
      }

    }




  return (
    <article className='contact' id="contact">
    <h2>{t('contact.title')}</h2>
    <div className='contact__container'>

      <form onSubmit={handleSubmit(sendEmail)} className='contact__container__form' id="contactForm">
        <div className='contact__container__form__id'>
          <input type="text" name="firstname" placeholder={t('contact.form.firstname')} {...register('firstname', {required: ""})} required className='contact__container__form__input' />

          <input type="text" name="lastname" placeholder={t('contact.form.lastname')}  {...register('lastname', {required: ""})} required className='contact__container__form__input'/>
        </div>

        <input type="text" name="object" placeholder={t('contact.form.object')} {...register('object', {required: ""})} required className='contact__container__form__input'/>


        <textarea name="message" placeholder={t('contact.form.message')} className='contactForm__message' {...register('message', {required: ""})} required></textarea>

        <input type="e-mail" name="email" placeholder={t('contact.form.email')}  {...register('email', {required: ""})} required className='contact__container__form__input'/>

        {
          sending ? 
          <button disabled>{t('contact.sending')}</button>
          :
          <button type="submit">{t('contact.send')}</button>
        }
        
        
      </form>



      <div className='contact__container__content'>
        <h3>{t('contact.subtitle1')}</h3>
        <h3>{t('contact.subtitle2')}</h3>
      </div>


    </div>
    
    
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      // limit={1}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={theme === 'dark' ? "light" : "dark"}
      // transition: Zoom,
      />
    

    </article>
  )
}

export default Contact