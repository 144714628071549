import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//FILES
import "./assets/sass/main.sass"
import {GlobalContext} from './contexts/GlobalContext'
import { setDefaultTheme } from "./utils/functions";

//PAGES
import Home from "./pages/Home";
import Legal from "./pages/Legal";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import AdminProjectPreview from "./pages/AdminProjectPreview";
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import Error from './pages/Error'

// COMPONENTS
import Settings from "./components/Settings";
import Footer from './components/Footer'

// TRANSLATIONS
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_en from "./translations/en/global.json"
import global_fr from "./translations/fr/global.json"


import { ToastContainer } from "react-toastify";

let language = 'en'

if(localStorage.getItem('language')){
  language = localStorage.getItem('language')
}else{
  localStorage.setItem('language', language)
}

i18next.init({
  interpolation: {escapeValue: false},
  lng: language,
  resources:{
      fr: {
          global: global_fr,
      },
      en: {
          global: global_en,
      }
  }
})


function App() {
  setDefaultTheme()
  const [minimizeProjects, setMinimizeProjects] = useState(true)
  const [theme, setTheme] = useState(document.body.className)
  const [token, setToken] = useState(localStorage.getItem('token') || '')
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en')
  
  return (
    <I18nextProvider i18n={i18next}>
      <GlobalContext.Provider value={{
      minimizeProjects, setMinimizeProjects, 
      theme, setTheme,
      token, setToken,
      language, setLanguage
      }}>
        <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme= {localStorage.getItem('theme') === 'dark' ? 'light' : 'dark'}
        // transition: Bounce,
      />
        <Router>

          <Settings/>

          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/legal' element={<Legal/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/admin' element={<Admin/>} />
            <Route path='/admin/:projectSlug' element={<AdminProjectPreview/>} />
            <Route path="/projects" element={<Projects/>}/>
            <Route path="/projets" element={<Projects/>}/>
            <Route path="/projects/:projectSlug" element={<Project/>}/>
            <Route path="/projets/:projectSlug" element={<Project/>}/>
            <Route path="*" element={<Error/>} />
          </Routes>

          <Footer/>

        </Router>

      </GlobalContext.Provider>
    </I18nextProvider>
  );
}

export default App;
