import React, { useContext } from 'react'
import { GlobalContext } from '../../contexts/GlobalContext'
import { useForm } from 'react-hook-form'
import { TOASTS } from '../../utils/toasts'
import { API_FUNCTIONS } from '../../utils/apiFunctions'

function AdminParameters() {
    const {token, setToken} = useContext(GlobalContext)
    const {register, handleSubmit, reset, formState: {errors}, watch} = useForm()

    const newPassword = watch('newPassword')

    const updatePassword = async(data) => {
        try{
            const response = await API_FUNCTIONS.updatePassword(data, token)

            if(response.status === 200){
                setToken(response.data.token)
                localStorage.setItem('token', response.data.token)
                TOASTS.success('Password successfully updated')
                reset()
            }else{
                TOASTS.error('Oups, something wetn wrong on password update...')
            }
            
        }catch(e){
            TOASTS.error('Oups, something wetn wrong on password update...')
        }
    } 

  return (
    <div className='parameters'>
        <h2>Parameters</h2>
        <h3>Update password</h3>

        <form onSubmit={handleSubmit(updatePassword)} className='parameters__passwordForm'>
            <input type='password' id='currentPassword' {...register('currentPassword', {required: "This field is required"})} autoComplete='new-password' placeholder=''/>
            <label htmlFor='currentPassword'>Old password</label>
            {errors.currentPassword && <span className='parameters__passwordForm__error danger'>{errors.currentPassword.message}</span>}

            <input 
                type="password" 
                id="newPassword" 
                {...register('newPassword', {
                    required: "Password is required",
                    minLength: {
                        value: 8,
                        message: "Password must contains at least 8 caracters"
                    }
                })} 
                placeholder=''
            />
            <label htmlFor='newPassword'>New password</label>
            {errors.newPassword && <span className='parameters__passwordForm__error danger'>{errors.newPassword.message}</span>}

            {newPassword && 
                <>
                    <input 
                        type="password" 
                        id="confirmPassword" {...register('confirmPassword',
                            {validate: value => value === newPassword || "Passwords do not match"}
                        )}
                        placeholder=''
                    />
                    <label htmlFor='confirmPassword'>Confirmation</label>
                    {errors.confirmPassword && <span className='parameters__passwordForm__error danger'>{errors.confirmPassword.message}</span>}
                </>
            }   

            <button type='submit'>Send</button>
        </form>


    </div>
  )
}

export default AdminParameters