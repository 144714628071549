import React, { useContext, useEffect, useState } from 'react'
import { clickOutModal, closeContent } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { GlobalContext } from '../../contexts/GlobalContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function SkillUpdate({skill, handleUpdateSkill, handleDeleteSkill, skillCategories}) {
    const {token} = useContext(GlobalContext)

    const [currentSkill, setCurrentSkill] = useState(null)

    useEffect(() => {
        setCurrentSkill(skill)
    }, [skill])


    const updateSkill = async(e) =>{
        e.preventDefault()

        try{
            const response = await API_FUNCTIONS.updateSkill(currentSkill, token)
            if(response.status === 200){
                TOASTS.success('Skill updated successfully')
                handleUpdateSkill(response.data.data)
                closeContent('updateSkillModal')
            }else{
                TOASTS.error('Oups, an error as occurred while updating skill...')
            }

        }catch(e){
            console.error(e)
            TOASTS.error('Oups, an error as occurred while updating skill...')
        }
    }

    const deleteSkill = async()=>{
        try{
            const response = await API_FUNCTIONS.deleteSkill(skill.id, token)

            if(response.status === 200){
                TOASTS.success('Skill deleted successfully')
                handleDeleteSkill(currentSkill)
                closeContent('updateSkillModal')
            }else{
                TOASTS.error('Oups, an error as occured while deleting skill...')
            }
        }catch(e){
            console.error(e)
            TOASTS.error('Oups, an error as occured while deleting skill...')
        }
    }
  return (
    <div className='modal' id='updateSkillModal' onClick={(e) => clickOutModal(e, 'updateSkillModal')}>
        <div className='modal__content'>
            <FontAwesomeIcon icon={faXmark} className='modal__content__close' onClick={() => closeContent('updateSkillModal')}/>
            <p className='modal__content__title'>Add Skill</p>
            {currentSkill &&
            
                <form onSubmit={e => updateSkill(e)} className='modalForm'>
                    <label>Skill name (English)</label>
                    <input type="text" value={currentSkill.skill_en_name} onChange={e => setCurrentSkill(prev => ({...prev, 'skill_en_name': e.target.value}))}required/>

                    <label>Nom de la compétence (Français)</label>
                    <input type="text" value={currentSkill.skill_fr_name} onChange={e => setCurrentSkill(prev => ({...prev, 'skill_fr_name': e.target.value}))} required/>

                    <div className='modalForm__favorite'>
                        <label>Favorite ?</label>
                        <input type='checkbox' checked={currentSkill.skill_is_favorite} onChange={e => setCurrentSkill(prev => ({...prev, 'skill_is_favorite': e.target.checked}))}/>
                    </div>

                    <label>Skill category</label>
                    <select defaultValue={skillCategories.find(item => item.id === currentSkill.skill_category_id)?.id} onChange={e => setCurrentSkill(prev => ({...prev, "skill_category_id": e.target.value}))}>
                        {
                            skillCategories?.map(skill => 
                                <option key={skill.id} value={skill.id}>{skill.skillCategory_en_name}</option>
                            )
                        }
                    </select>

                    <button type="submit">Update</button>
                </form>
            }

            <p className='modal__content__title danger'>Delete Skill</p>

            <button className='btn btn--danger' onClick={() => deleteSkill()}>Delete</button>
        </div>
    </div>
  )
}

export default SkillUpdate