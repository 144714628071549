import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { clickOutModal, closeContent } from '../../utils/functions'
import { GlobalContext } from '../../contexts/GlobalContext'
import { useForm } from 'react-hook-form'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function SkillCategoriesUpdate({cat, handleUpdateCategory, handleDeleteCategory}) {
  const {token} = useContext(GlobalContext)

  const [currentCat, setCurrentCat] = useState(null)

  useEffect(() => {
    setCurrentCat(cat)
  }, [cat])

  const updateCategory = async(e) =>{
    e.preventDefault()
    try{
      const response = await API_FUNCTIONS.updateSkillCategory(currentCat, token)
      if(response.status === 200){
        TOASTS.success('Skill category successfully updated')
        handleUpdateCategory(response.data.data)
        closeContent('updateSkillCategoryModal')
      }else{
        TOASTS.error('Oups, an error as occured on skill category update...')
      }
    }catch(e){
      TOASTS.error('Oups, an error as occured on skill category update...')
    }
  }

  const deleteCategory = async()=>{
    try{
      const response = await API_FUNCTIONS.deleteSkillCategory(cat.id, token)
      if(response.status === 200){
        TOASTS.success('Skill category deleted successfully')
        handleDeleteCategory(cat)
        setCurrentCat(null)
        closeContent('updateSkillCategoryModal')
      }else{
        TOASTS.error('Oups, an error as occured on deleting skill category...')
      }
    }catch(e){
      TOASTS.error('Oups, an error as occured on deleting skill category...')
      console.error(e)
    }
  }

  return (
    <div className='modal' id={'updateSkillCategoryModal'} onClick={(e) => clickOutModal(e, 'updateSkillCategoryModal')}>
        <div className='modal__content'>
            <FontAwesomeIcon icon={faXmark} className='modal__content__close' onClick={() => closeContent('updateSkillCategoryModal')}/>
            <p className='modal__content__title'>Update Skill Category</p>
            {currentCat !== null &&
            
              <form onSubmit={(e) => updateCategory(e)} className='modalForm'>
                  <label>Skill name (English)</label>
                  <input type="text" value={currentCat?.skillCategory_en_name} onChange={(e) => setCurrentCat(prev => ({...prev, 'skillCategory_en_name': e.target.value}))} required/>

                  <label>Nom de la catégorie (Français)</label>
                  <input type="text" value={currentCat?.skillCategory_fr_name} onChange={(e) => setCurrentCat(prev => ({...prev, 'skillCategory_fr_name': e.target.value}))} required/>

                  <button type="submit">Update</button>
              </form>
            }

            <p className='modal__content__title danger'>Delete Skill Category</p>

            <button className='btn btn--danger' onClick={() => deleteCategory()}>Delete</button>

        </div>
    </div>
  )
}

export default SkillCategoriesUpdate