import React, { useContext } from 'react'
import { AdminContext } from '../../contexts/AdminContext'

function AdminMenu() {
    const {adminUrl, setAdminUrl} = useContext(AdminContext)

    
  return (
    <nav className='adminNav'>
        <div 
            onClick={() => {
                setAdminUrl('projects'); 
                localStorage.setItem('adminUrl', 'projects')
            }} 
            className={adminUrl === 'projects' ? 'adminNav__item--selected' : 'adminNav__item'}
        >
            Projects
        </div>

        <div 
            onClick={() => {
                setAdminUrl('skills'); 
                localStorage.setItem('adminUrl', 'skills')
            }} 
            className={adminUrl === 'skills' ? 'adminNav__item--selected' : 'adminNav__item'}
        >
            Skills
        </div>
        
        <div 
            onClick={() => {
                setAdminUrl('skillCategories'); 
                localStorage.setItem('adminUrl', 'skillCategories')
            }} 
            className={adminUrl === 'skillCategories' ? 'adminNav__item--selected' : 'adminNav__item'}
        >
            Skill Categories
        </div>

        <div 
            onClick={() => {
                setAdminUrl('parameters'); 
                localStorage.setItem('adminUrl', 'parameters')
            }} 
            className={adminUrl === 'parameters' ? 'adminNav__item--selected' : 'adminNav__item'}
            >
                Parameters
        </div>

    </nav>
  )
}

export default AdminMenu