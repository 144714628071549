import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { clickOutModal, closeContent } from '../../utils/functions';
import { useForm } from 'react-hook-form';
import { GlobalContext } from '../../contexts/GlobalContext';
import { TOASTS } from '../../utils/toasts';
import { API_FUNCTIONS } from '../../utils/apiFunctions';

function SkillCategoriesAdd({handleAddSkillCategory}) {
    const {token} = useContext(GlobalContext)

    const{register, handleSubmit, formState: {errors}, reset} = useForm()

    const addCategory = async(data) => {
        try{
            const response = await API_FUNCTIONS.addSkillCategory(data, token)
            if(response.status === 201){
                TOASTS.success('Skill category successfully created')
                handleAddSkillCategory(response.data.data)
                reset()
                closeContent('addSkillCategoryModal')
            }else{
                TOASTS.error('Oups, something went wrong when adding Skill Category...')
            }
        }catch(e){
            console.error('Error caught in catch block:', e);
            TOASTS.error('Oups, something went wrong when adding Skill Category...')
        }
    }

  return (
    <div className='modal' id='addSkillCategoryModal' onClick={(e) => clickOutModal(e, 'addSkillCategoryModal')}>
        <div className='modal__content'>
            <FontAwesomeIcon icon={faXmark} className='modal__content__close' onClick={() => closeContent('addSkillCategoryModal')}/>
            <p className='modal__content__title'>Add Skill Category</p>

            <form onSubmit={handleSubmit(addCategory)} className='modalForm'>
                <label>Skill name (English)</label>
                <input type="text" {...register('skillCategory_en_name', {required: "This field is required"})} required/>

                <label>Nom de la catégorie (Français)</label>
                <input type="text" {...register('skillCategory_fr_name', {required: "This field is required"})} required/>

                <button type="submit">Create</button>
            </form>

        </div>
    </div>
  )
}

export default SkillCategoriesAdd