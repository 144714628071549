import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../contexts/GlobalContext'
import { useForm } from 'react-hook-form'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import { TOASTS } from '../utils/toasts'

function Login() {
    const navigate = useNavigate()
    const {token, setToken} = useContext(GlobalContext)

    const {register, handleSubmit, formState: {errors}, reset} = useForm()

    useEffect(() => {
        if(token){
            navigate('/admin')
        }
    }, [token])


    const loginAdmin = async(data) => {
        try{
            const response  = await API_FUNCTIONS.login(data)

            if(response.status === 200){
                const responseToken = response.data.data.access_token.token
                TOASTS.success("Welcome back "+response.data.data.user.firstname+' !')
                localStorage.setItem('token', responseToken)
                reset()
                setToken(responseToken)
            }else{
                TOASTS.error('Something went wrong...')
            }
            
        }catch(e){
            TOASTS.error('Something went wrong...')
        }
    }

  return (
    <div className='login mainContainer'>
        <h1>Login</h1>
        <form onSubmit={handleSubmit(loginAdmin)} id='loginForm' className='login__form'>
            <input type='email' id='loginEmail' {...register('email', {required: "The email field is required for the login"})} placeholder=''/>
            <label htmlFor='loginEmail'>Email</label>
            {errors.email && <span className="login__form__error danger">{errors.email.message}</span>}

            <input type="password" id='loginPassword' {...register('password', {required: "The password field is required for the login"})} placeholder=''/>
            <label htmlFor='loginPassword'>Password</label>
            {errors.password && <span className="login__form__error danger">{errors.password.message}</span>}

            <button type='submit'>Login</button>
        </form>
    </div>
  )
}

export default Login