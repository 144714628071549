import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { API_STORAGE } from '../utils/apiFunctions';
import { GlobalContext } from '../contexts/GlobalContext';

function Carousel({images, handleCloseCarousel}) {
  const {language} = useContext(GlobalContext)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isCarouselMoving, setIsCarouselMoving] = useState('')

  document.body.style.overflow = 'hidden';
  const closeCarousel = () => {
      document.body.style.overflow = 'initial';
      handleCloseCarousel()
  }

  const makePreviewVisible = () => {
    const currentImage = document.getElementById('carouselPreview-'+currentIndex).getBoundingClientRect()
    const previewContainer = document.getElementById('carouselPreview') 
    const threshold = 15
    if(window.innerWidth > 680){
      if (currentImage.top < threshold) {
        previewContainer.scrollTop -= Math.ceil(threshold - currentImage.top);
      } else if (currentImage.bottom > window.innerHeight - threshold) {
        previewContainer.scrollTop += Math.ceil(currentImage.bottom - (window.innerHeight - threshold));
      }
    }else{
      if (currentImage.left < threshold) {
        previewContainer.scrollLeft -= Math.ceil(threshold - currentImage.left);
      } else if (currentImage.right > window.innerWidth - threshold) {
        previewContainer.scrollLeft += Math.ceil(currentImage.right - (window.innerWidth - threshold));
      }
    }
  }

  const handleImageUp = () => {
    setCurrentIndex(prevIndex => (prevIndex < images.length - 1 ? prevIndex + 1 : 0))
  }

  const handleImageDown = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : images.length - 1))
  }

  const handleKeyDown = (e) => {
    if(e.key === "Escape"){
      closeCarousel()
    }else if(e.key === 'ArrowRight' || e.key === ' ' || e.key === 'Enter'){
      handleImageUp()
    }else if(e.key === 'ArrowLeft'){
      handleImageDown()
    }
  }

  const handleWheel = (e) => {
    if(!e.target.closest('#carouselPreview')){
      if (e.deltaY > 0) {
        handleImageUp();
      } else if (e.deltaY < 0) {
        handleImageDown();
      }
    }
  };


  let touchStart = null, touchEnd = null

  const handleTouchStart = (e) => {
    touchStart = e.targetTouches[0].clientX
  };

  const handleTouchMove = (e) => {
    touchEnd = e.targetTouches[0].clientX
    if(!e.target.closest('#carouselPreview')){
      const delta = touchEnd - touchStart
      document.getElementById('carouselContainer').style.transform = `translateX(${delta}px)`
      if(delta < 0){
        setIsCarouselMoving('left')
      }else if(delta > 0){
        setIsCarouselMoving('right')
      }
    }
  };

  const handleTouchEnd = (e) => {
    
    
    if (!touchStart || !touchEnd || e.target.closest('#carouselPreview')) return
    const distance = touchStart - touchEnd
    const threshold = 50; 
    document.getElementById('carouselContainer').style.transition = '0.2s ease-in-out'
    if (distance > threshold) {
      document.getElementById('carouselContainer').style.transform = `translateX(-${document.getElementById('carouselContainer').getBoundingClientRect().width}px)`
      setTimeout(() => {
        handleImageUp();
        document.getElementById('carouselContainer').style.transition = ''
        document.getElementById('carouselContainer').style.transform = 'translateX(0px)'
        setIsCarouselMoving('')
      }, 250);
    } else if (distance < -threshold) {
      document.getElementById('carouselContainer').style.transform = `translateX(${document.getElementById('carouselContainer').getBoundingClientRect().width}px)`
      setTimeout(() => {
        handleImageDown();
        document.getElementById('carouselContainer').style.transition = ''
        document.getElementById('carouselContainer').style.transform = 'translateX(0px)'
        setIsCarouselMoving('')
      }, 250);
    }else{
      setIsCarouselMoving('')
    }

    
    touchStart = null 
    touchEnd = null
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('wheel', handleWheel)
    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('wheel', handleWheel)
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])

  useEffect(() => {
    makePreviewVisible()
  }, [currentIndex])
    
  

  return (
    <div className='carousel'>

        <FontAwesomeIcon icon={faXmark} className='carousel__close' onClick={() => closeCarousel()}/>

        <div className='carousel__container' id='carouselContainer'>
        {
              // isCarouselMoving === 'right' && 
              window.innerWidth < 991 && 
              <picture id='carouselContainer-previousImage' className='carousel__container--rightAside'>
                <source 
                srcSet={`
                    ${API_STORAGE + images[currentIndex === 0 ? images.length-1 : currentIndex-1].urls['small-webp']} 680w,
                    ${API_STORAGE + images[currentIndex === 0 ? images.length-1 : currentIndex-1].urls['medium-webp']} 990w,
                    ${API_STORAGE + images[currentIndex === 0 ? images.length-1 : currentIndex-1].urls['large-webp']} 1200w
                    `}
                sizes="
                    (max-width: 680px) 100vw,
                    (max-width: 990px) 100vw,
                    100vw"
                type="image/webp"
                />

                <source 
                srcSet={`
                    ${API_STORAGE + images[currentIndex === 0 ? images.length-1 : currentIndex-1].urls['small-jpg']} 680w,
                    ${API_STORAGE + images[currentIndex === 0 ? images.length-1 : currentIndex-1].urls['medium-jpg']} 990w,
                    ${API_STORAGE + images[currentIndex === 0 ? images.length-1 : currentIndex-1].urls['large-jpg']} 1200w
                    `}
                sizes="
                    (max-width: 680px)100vw,
                    (max-width: 990px)100vw,
                    100vw"
                type="image/jpeg"
                />
            
                <img 
                src={API_STORAGE + images[currentIndex === 0 ? images.length-1 : currentIndex-1].urls['large-webp']} 
                alt={language === 'fr' ? images[currentIndex === 0 ? images.length-1 : currentIndex-1].image_fr_alt : images[currentIndex === 0 ? images.length-1 : currentIndex-1].image_en_alt}
                />
              </picture>
            }
          

            <picture id='carouselContainer-middleImage' >
                <source 
                srcSet={`
                    ${API_STORAGE + images[currentIndex].urls['small-webp']} 680w,
                    ${API_STORAGE + images[currentIndex].urls['medium-webp']} 990w,
                    ${API_STORAGE + images[currentIndex].urls['large-webp']} 1200w
                    `}
                sizes="
                    (max-width: 680px) 100vw,
                    (max-width: 990px) 100vw,
                    100vw"
                type="image/webp"
                />

                <source 
                srcSet={`
                    ${API_STORAGE + images[currentIndex].urls['small-jpg']} 680w,
                    ${API_STORAGE + images[currentIndex].urls['medium-jpg']} 990w,
                    ${API_STORAGE + images[currentIndex].urls['large-jpg']} 1200w
                    `}
                sizes="
                    (max-width: 680px)100vw,
                    (max-width: 990px)100vw,
                    100vw"
                type="image/jpeg"
                />
            
                <img 
                src={API_STORAGE + images[currentIndex].urls['large-webp']} 
                alt={language === 'fr' ? images[currentIndex].image_fr_alt : images[currentIndex].image_en_alt}
                />
            </picture>


            {
            // isCarouselMoving === 'left' && 
            window.innerWidth < 991 &&
            <picture id='carouselContainer-nextImage' className='carousel__container--leftAside'>
              <source 
              srcSet={`
                  ${API_STORAGE + images[currentIndex === images.length-1 ? 0 : currentIndex+1].urls['small-webp']} 680w,
                  ${API_STORAGE + images[currentIndex === images.length-1 ? 0 : currentIndex+1].urls['medium-webp']} 990w,
                  ${API_STORAGE + images[currentIndex === images.length-1 ? 0 : currentIndex+1].urls['large-webp']} 1200w
                  `}
              sizes="
                  (max-width: 680px) 100vw,
                  (max-width: 990px) 100vw,
                  100vw"
              type="image/webp"
              />

              <source 
              srcSet={`
                  ${API_STORAGE + images[currentIndex === images.length-1 ? 0 : currentIndex+1].urls['small-jpg']} 680w,
                  ${API_STORAGE + images[currentIndex === images.length-1 ? 0 : currentIndex+1].urls['medium-jpg']} 990w,
                  ${API_STORAGE + images[currentIndex === images.length-1 ? 0 : currentIndex+1].urls['large-jpg']} 1200w
                  `}
              sizes="
                  (max-width: 680px)100vw,
                  (max-width: 990px)100vw,
                  100vw"
              type="image/jpeg"
              />
          
              <img 
              src={API_STORAGE + images[currentIndex === images.length-1 ? 0 : currentIndex+1].urls['large-webp']} 
              alt={language === 'fr' ? images[currentIndex === images.length-1 ? 0 : currentIndex+1].image_fr_alt : images[currentIndex === images.length-1 ? 0 : currentIndex+1].image_en_alt}
              />
            </picture>
          }
            

        </div>

        <div className='carousel__preview' id='carouselPreview'>
            {images.sort((a, b) => a.image_index - b.image_index).map((image, index) => 

                <picture key={index} id={`carouselPreview-${index}`} className={'carousel__preview__image ' + (currentIndex === index ? 'carousel__preview__image--active' : '')} onClick={() => setCurrentIndex(index)}>
                  <source 
                    srcSet={`
                      ${API_STORAGE + image.urls['small-webp']} 680w,
                      ${API_STORAGE + image.urls['medium-webp']} 990w,
                      ${API_STORAGE + image.urls['large-webp']} 1200w
                      `}
                    sizes="(max-width: 680px) 200px,
                    (max-width: 990px) 200px,
                          200px"
                    type="image/webp"
                  />
    
                  <source 
                    srcSet={`
                      ${API_STORAGE + image.urls['small-jpg']} 680w,
                      ${API_STORAGE + image.urls['medium-jpg']} 990w,
                      ${API_STORAGE + image.urls['large-jpg']} 1200w
                      `}
                    sizes="(max-width: 680px)200px,
                    (max-width: 990px)200px,
                    200px"
                    type="image/jpeg"
                  />
                
                  <img 
                    src={API_STORAGE + image.urls['large-webp']} 
                    alt={language === 'fr' ? image.image_fr_alt : image.image_en_alt}
                  />
                </picture>
            )}

        </div>

    </div>
  )
}

export default Carousel