import React, { useEffect, useState } from 'react'

import LayoutWelcome from '../components/LayoutWelcome'
// import {changeTheme} from "../utils/functions"

import Menu from '../components/Menu'
import ProjectsHome from '../components/ProjectsHome'
import Skills from '../components/Skills'
import Contact from '../components/Contact'
import { useSSR } from 'react-i18next'

function Home() {
  // const [ position, setPosition] = useState(window.scrollY)

  // console.log('taille de la page', document.body.scrollHeight);
  // console.log('TEST', document.body.scrollHeight - window.innerHeight);

  // useEffect(() => {
  //   console.log("test position scroll", position)
  // }, [position])

  // const updatePosition = () => {
  //   setPosition(window.scrollY)
  // }

  // window.addEventListener("scroll", updatePosition)

  return (
    <div className='homePage'>
      <Menu/>
      <LayoutWelcome/>
      <ProjectsHome/>
      <Skills/>
      <Contact/>

    </div>
  )
}

export default Home