import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/GlobalContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import SkillCategoriesAdd from '../modals/SkillCategoriesAdd'
import SkillCategoriesUpdate from '../modals/SkillCategoriesUpdate'
import { openContent } from '../../utils/functions'

function AdminSkillCategories() {
  const {token} = useContext(GlobalContext)

  const [isLoaded, setIsLoaded] = useState(false)
  const [categories, setCategories] = useState([])
  const [modalOpened, setModalOpened] = useState(null)
  const [updatingCat, setUpdatingCat] = useState(null)

  const loadingDatas = async()=>{
    try{
      const response = await API_FUNCTIONS.allSkillCategories()

      if(response.status === 200){
        setCategories(response.data.data)
      }else{
        TOASTS.error('Oups, something went wrong on data loading')
      }
    }catch(e){
      TOASTS.error('Oups, something went wrong on data loading')
    }finally{
      setIsLoaded(true)
    }
  }

  useEffect(() => {
    if(!isLoaded){
      loadingDatas()
    }
  }, [])

  const handleAddSkillCategory = (newCat) => {
    setCategories(prev => [...prev, newCat])
  } 

  const handleUpdateCategory = (updatedCat) => {
    const updatedCategories = categories.map(cat => (
      cat.id === updatedCat.id ? 
      updatedCat
      :
      cat
    ))
    setCategories(updatedCategories)
  }
  const handleDeleteCategory = (cat) => {
    setCategories(prev => prev.filter(item => item.id !== cat.id))
  }


  return (
    <div className='skillCategories mainContainer'>
        <h2>Skill Categories</h2>
        {isLoaded &&
          <>
            {/* <h3>Categories list</h3> */}

            <div className='skillCategories__list'>
              <button className='skillCategories__list__createBtn' onClick={() => openContent('addSkillCategoryModal')}>Create</button>
              {categories.map(cat => 
                <div key={cat.id} className='skillCategories__list__item' onClick={() => {setUpdatingCat(cat); openContent('updateSkillCategoryModal')}}>
                  {cat.skillCategory_en_name}
                </div>
              )}
            </div>

            <SkillCategoriesAdd handleAddSkillCategory={handleAddSkillCategory}/>
            <SkillCategoriesUpdate cat={updatingCat} handleUpdateCategory={handleUpdateCategory} handleDeleteCategory={handleDeleteCategory}/>
          </>
        }
    </div>
  )
}

export default AdminSkillCategories