import { faSquareCaretLeft } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../contexts/GlobalContext'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import ProjectCard from '../components/ProjectCard'
import ProjectItem from '../components/ProjectItem'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Projects() {
    const navigate = useNavigate()
    const {language} = useContext(GlobalContext)

    const [isLoaded, setIsLoaded] = useState(false)
    const [projects, setProjects] = useState([])
    const [years, setYears] = useState([])
    
    const loadingProjects = async()=>{
        try{
            const response = await API_FUNCTIONS.allProjects('public')
            
            if(response.status === 200){
                const projectsYears = new Set()
                response.data.data.forEach( project => {
                    projectsYears.add(project.project_date.split('-')[0])
                });
                setYears(Array.from(projectsYears))
                setProjects(response.data.data)
            }
        }catch(e){
            console.error(e)
        }finally{
            setIsLoaded(true)
        }
    }

    useEffect(() => {
        if(!isLoaded){
            loadingProjects()
        }
    }, [])
  return (
    <div className='mainContainer projectsPage'>
      <Helmet>
        {/* <title>Yann Ghistelinck - {project.project_name}</title>
        <meta name="description" content={project.project_fr_synopsis} />
        <meta name="keywords" content={project.skills.map(skill => skill.skill_fr_name)} />

        <meta property="og:title" content={project.project_name} />
        <meta property="og:description" content={project.project_fr_synopsis} />
        <meta property="og:image" content={API_STORAGE+project.cover_urls['small-webp']} />
        <meta property="og:url" content={'https://yann-ghistelinck.fr/projects/'+project.project_slug} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={project.project_name} />
        <meta name="twitter:description" content={project.project_fr_synopsis} />
        <meta name="twitter:image" content={API_STORAGE+project.cover_urls['thumb-webp']} /> */}
      </Helmet>


        <FontAwesomeIcon icon={faSquareCaretLeft} onClick={() => navigate('/')} className='projectPage__return'/>
        <h1 className='projectPage__title'>{language === 'fr' ? 'Tous mes projets' : "All projects"}</h1>

        {
            isLoaded ?

            years.sort((a, b) => b - a).map((year, index) =>
                <div key={index} className='projectsPage__container'>
                    <div className='projectsPage__container__year'>
                        <div className='projectsPage__container__year__line'></div>
                        <h2 className='projectsPage__container__year__text'>{year}</h2>
                    </div>

                    {
                        projects.filter(item => item.project_date.split('-')[0] === year).sort((a, b) => new Date(b.project_date) - new Date(a.project_date)).map(project => 
                            // <ProjectCard key={project.id} project={project} />
                            <ProjectItem key={project.id} project={project}/>
                        )
                    }
                </div>
            )
            :
            <div className='projectsPage__loader'>
                <FontAwesomeIcon icon={faSpinner} className='spinner'/>
            </div>
        }
        
    </div>
  )
}

export default Projects