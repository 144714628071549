import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Legal() {
  const [t] = useTranslation("global")
  return (
    <div className='legal'>
      <h1>{t('legal.title')}</h1>

      <p className='legal__ariane'><Link to='/'>{t('menu.home')}</Link>  -  {t('legal.title')}</p>

      <h2>{t('legal.information.title').toUpperCase()}</h2>

      <p>{t('legal.information.name')} : Yann Ghistelinck</p>
      <p>{t('legal.information.city')} : Le Mans 72000</p>
      <p>{t('legal.information.email')} : <a href="mailto:contact@yann-ghistelinck.fr">contact@yann-ghistelinck.fr</a></p>
      <p>{t('legal.information.phone')} : <a href="tel:+33686058335">+33 6 82 05 83 35</a></p>


      <h2>{t('legal.host.title').toUpperCase()}</h2>

      <p>{t('legal.host.name')} : o2switch</p>
      <p>{t('legal.host.adress')} : Chemin des Pardiaux</p>
      <p>{t('legal.host.city')} : Clermont Ferrand 63000</p>
      <p>{t('legal.host.email')} : support@o2switch.fr</p>
      <p>{t('legal.host.support')} : <a href="https://www.o2switch.fr/support-hebergeur/">https://www.o2switch.fr/support-hebergeur/</a></p>
    </div>
  )
}

export default Legal