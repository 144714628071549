import React, {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import ProjectCard from './ProjectCard'


// import projects from '../assets/data/projects.json'
import {GlobalContext} from '../contexts/GlobalContext'
import ScrollEffect from '../containers/ScrollEffect'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import { TOASTS } from '../utils/toasts'
import { useNavigate } from 'react-router-dom'

function ProjectsHome() {
    const [t] = useTranslation('global')
    const navigate = useNavigate()

    const {minimizeProjects, setMinimizeProjects, language} = useContext(GlobalContext)
    const [numberOfProjects, setNumberOfProjects] = useState(3)

    const [isLoaded, setIsloaded] = useState(false)
    const [projects, setProjects] = useState([])

    const loadingProjects = async() => {
      try{
        const response = await API_FUNCTIONS.allProjects('favorites')

        if(response.status === 200){
          setProjects(response.data.data)
          setNumberOfProjects(minimizeProjects ? 3 : projects.length)
        }else{
          TOASTS.error('Oups, an error as occured while projects loading...')
        }
      }catch(e){
            TOASTS.error('Oups, an error as occured while projects loading...')
      }finally{
        setIsloaded(true)
      }
    }

    useEffect(() => {
      if(!isLoaded){
        loadingProjects()
      }
    }, [isLoaded])
    
    
  return (
    <article className='homeProjects' id='projects'>
        <h2 className='homeProjects__title'>{t('projects.title')}</h2>
        <div className='homeProjects__global'>
          <div className='homeProjects__container' id='projectContainer'>
            {projects.sort((a, b) => b.id - a.id).sort((a, b) => b.project_is_favorite - a.project_is_favorite).slice(0,numberOfProjects).map((project, index) => 
              <ScrollEffect key={index}>
                <ProjectCard project={project} />
              </ScrollEffect>
              
            )}
            
          </div>

          <div className="moreButton">
            <button onClick={() => navigate(language === 'fr' ? '/projets' : '/projects')}>
                <span>
                  {t('projects.more')}
                </span>
            </button>
            {/* {minimizeProjects ? 
              <button onClick={() => (
                setNumberOfProjects(projects.length), 
                setMinimizeProjects(false), 
                setTimeout(() => {
                  document.getElementById('projectContainer').classList.add('projects__container--opened')
                  document.getElementById('projectContainer').classList.remove('projects__container--closed')
                }, 100)
                )}><span>{t('projects.more')}</span></button>
            : 
              <button onClick={() => (
                setTimeout(() => {
                  setNumberOfProjects(3)
                  setMinimizeProjects(true)}, 400),
                document.getElementById('projectContainer').classList.remove('projects__container--opened'),
                document.getElementById('projectContainer').classList.add('projects__container--closed')
                )}><span>{t('projects.less')}</span></button>
            } */}
          </div>
        </div>
          
    </article>
  )
}

export default ProjectsHome