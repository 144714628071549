import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFigma, faReact, faLaravel, faGithub, faTrello, faUbuntu, faSymfony } from '@fortawesome/free-brands-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'

import ScrollEffect from '../containers/ScrollEffect'

function Skills() {
    const [t] = useTranslation("global")

    return (
        <article className='skills' id="skills">
            <h2>{t('skills.title')}</h2>
            <div className='skills__container'>
                <div className='skills__container__bloc'>
                    <h3 className='skills__container__bloc__title'>Design</h3>

                    <ScrollEffect>
                        
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faFigma} /><span>Figma</span></div>
                    </ScrollEffect>
                    
                </div>
                
                <div className='skills__container__bloc'>
                    <h3 className='skills__container__bloc__title'>Frontend</h3>

                    <ScrollEffect>
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faReact} /><span>React</span></div>
                    </ScrollEffect>
                    
                </div>

                <div className='skills__container__bloc'>
                    <h3 className='skills__container__bloc__title'>Backend</h3>

                    <ScrollEffect>
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faLaravel} /><span>Laravel</span></div>
                    </ScrollEffect>

                    <ScrollEffect>
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faSymfony} /><span>Symfony</span></div>
                    </ScrollEffect>
                    
                    <ScrollEffect>
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faDatabase} /><span>MySQL</span></div>
                    </ScrollEffect>
                    
                </div>


                <div className='skills__container__bloc'>
                    <h3 className='skills__container__bloc__title'>Stack</h3>

                    <ScrollEffect>
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faGithub} /><span>Git & Github</span></div>
                    </ScrollEffect>
                    <ScrollEffect>
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faTrello} /><span>Trello</span></div>
                    </ScrollEffect>
                    
                    <ScrollEffect>
                        <div className='skills__container__bloc__item'><FontAwesomeIcon icon={faUbuntu} /><span>Linux</span></div>
                    </ScrollEffect>
                    
                </div>

            </div>
        </article>
    )
}

export default Skills