import { faSquareCaretLeft } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalContext } from '../contexts/GlobalContext'
import { API_FUNCTIONS, API_STORAGE } from '../utils/apiFunctions'
import { faCircleXmark, faMinus, faPlus, faStar, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faStar as faEmptyStar } from '@fortawesome/free-regular-svg-icons'
import { TOASTS } from '../utils/toasts'
import UpdateImage from '../components/modals/UpdateImage'
import { openContent } from '../utils/functions'
import ReactQuill, {Quill} from 'react-quill'

function AdminProjectPreview() {
    const navigate = useNavigate()
    const {projectSlug} = useParams()
    const {language} = useContext(GlobalContext)
    const {token, setToken} = useContext(GlobalContext)
    const [isTokenValid, setIsTokenValid] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)
    const [imageFormOpened, setImageFormOpened] = useState(false)
    const [project, setProject] = useState(null)
    const [images, setImages] = useState([])
    const [newImage, setNewImage] = useState(null)
    const [updatingImage, setUpdatingImage] = useState(null)
    const [skills, setSkills] = useState([])
    const [allSkills, setAllSkills] = useState([])

    const [editContent, setEditContent] = useState(null)
    const [isEditingProject, setIsEditingProject] = useState(false)

    const loadingProject = async() => {
        try{
            const [projectsData, skillsData] = await Promise.all([
                API_FUNCTIONS.oneProjectBySlug(projectSlug),
                API_FUNCTIONS.allSkills()
            ])
            if(projectsData.status === 200){
                setProject(projectsData.data.data)
                setImages(projectsData.data.data.images)
                setSkills(projectsData.data.data.skills)
                setEditContent(projectsData.data.data)
            }else{
                navigate('/login')
            }

            if(skillsData.status === 200){
                setAllSkills(skillsData.data.data)
            }else{
                TOASTS.error('Oups, an error as occured while loading skills')
            }
        }catch(e){
            console.error(e)
        }finally{
            setIsLoaded(true)
        }
    }


    const checkUser = async()=>{
        const checkUser = await API_FUNCTIONS.currentUser(token)
        if(checkUser.status !== 200){
            localStorage.removeItem('token')
            setToken(null)
        }else{
            setIsTokenValid(true)
        }
    }

          
    useEffect(() => {
        loadingProject()
    }, [projectSlug])

    useEffect(() => {
    checkUser()

    if(!token){
        navigate('/')
    }
    }, [token])

    useEffect(() => {
        setNewImage(null)
    }, [imageFormOpened])
    
    useEffect(() => {
        if(editContent !== project){
            setIsEditingProject(true)
        }
    }, [editContent])




    const handleToggleFavoriteSkill = async(skill) => {
        try{
        const response = await API_FUNCTIONS.updateProjectSkill(skill.pivot.id, token)

            if(response.status === 200){
                const pivot = {...skill.pivot, is_favorite: skill.pivot.is_favorite ? 0 : 1}
                const updatedSkills = skills.map(s => 
                    s.id === skill.id ? 
                        {...skill, pivot: pivot}
                    :
                        s
                )
                setSkills(updatedSkills)
            }else{
                TOASTS.error('Oups, an error as occured on toggle favorite skill...')
            }
        }catch(e){
            TOASTS.error('Oups, an error as occured on toggle favorite skill...')
            console.error(e)
        }
    }

    
    const handleRemoveSkill = async(id) => {
        try{
            const request = {
                skill_id: id
            }
            const response = await API_FUNCTIONS.detachProjectSkill(project.id, request, token)
            if(response.status === 200){
                setSkills(skills.filter(item => item.id !== id))
            }else{
                TOASTS.error('Oups, an error as occured while removing skill...')
            }
        }catch(e){
            TOASTS.error('Oups, an error as occured while removing skill...')
            console.error(e)
        }
    }

    const handleAddSkill = async(skill)=>{
        try{
            const response = await API_FUNCTIONS.attachProjectSkill(project.id, {skill_id: skill.id}, token)
            if(response.status === 200){
                 setSkills(prev => [...prev, {...skill, pivot: response.data.data}])
            }else{
                TOASTS.error('Oups, an error as occured while adding skill...')
            }
        }catch(e){
            TOASTS.error('Oups, an error as occured while adding skill...')
            console.error(e)
        }
    }

    const handleAddImage = async(e) => {
        e.preventDefault()
        console.log('new image', newImage);
        try{
            const formData = new FormData()
            formData.append('image_name', newImage.image_name)
            formData.append('image_en_alt', newImage.image_en_alt)
            formData.append('image_fr_alt', newImage.image_fr_alt)
            formData.append('project_id', project.id)
            formData.append('image_index', images.length >0 ? images.sort((a, b) => b.image_index - a.image_index)[0].image_index+1 : 0)
            const response  = await API_FUNCTIONS.addImages(formData, token)
            if(response.status === 201){
                TOASTS.success('Image add successfully')
                setImages([...images, response.data.data])
                setImageFormOpened(false)
            }else{
                TOASTS.error('Oups, an error as occured while adding new image...')
            }
        }catch(e){
            console.error(e)
            TOASTS.error('Oups, an error as occured while adding new image...')
        }
    }

    const handleUpdateImage = (img) => {
        const updatedImages = images.map(image => 
            image.id === img.id ? 
                img
            :
                image
        )
        setImages(updatedImages)
        setUpdatingImage(null)
    }

    const handleMoveImageUp = async(currentImage)=>{
        let collatImage = images.sort((a,b) => b.image_index - a.image_index).filter(item => item.image_index<currentImage.image_index)[0]
        if(!collatImage){
            TOASTS.error('Error with images index...')
            return 
        }

        const response = await API_FUNCTIONS.updateImage({...currentImage, image_index: collatImage.image_index}, token)
        if(response.status === 200){
            currentImage = {...currentImage, image_index: response.data.data.image_index}

            const collatResponse = await API_FUNCTIONS.updateImage( {...collatImage, image_index: collatImage.image_index+1}, token)
            if(collatImage && collatResponse.status === 200){
                collatImage = {...collatImage, image_index: collatResponse.data.data.image_index}
            }else{
                TOASTS.error('Oups, an error as occured while rank up collat image...')
            }

            const updatedImages = images.map(image => 
                image.id === currentImage.id ? 
                    currentImage
                :
                    image.id === collatImage.id ?
                        collatImage
                    :
                        image
            )
            setImages(updatedImages)
        }else{
            TOASTS.error('Oups, an error as occured while rank up image...')
        }
    }
    const handleMoveImageDown = async(currentImage)=>{
        let collatImage = images.sort((a, b) => a.image_index - b.image_index).filter(item => item.image_index > currentImage.image_index)[0]
        if(!collatImage){
            TOASTS.error('Error with images index...')
            return
        }

        const response = await API_FUNCTIONS.updateImage({...currentImage, image_index: collatImage.image_index}, token)
        if(response.status === 200){
            currentImage = {...currentImage, image_index: response.data.data.image_index}
            const collatResponse = await API_FUNCTIONS.updateImage({...collatImage, image_index: collatImage.image_index-1}, token)
            if(collatResponse.status === 200){
                collatImage = {...collatImage, image_index: collatResponse.data.data.image_index}
            }else{
                TOASTS.error('Oups, an error as occured while rank down collat image...')
            }
            const updatedImages = images.map(image => 
                image.id === currentImage.id ?
                    currentImage
                :
                    image.id === collatImage.id ? 
                        collatImage
                    :
                        image
                )
            setImages(updatedImages)
        }else{
            TOASTS.error('Oups, an error as occured while rank down image...')
        }

    }

    const handleDeleteImage = async(id) => {
        try{
            const response = await API_FUNCTIONS.deleteImage(id, token)
            if(response.status === 200){
                setImages(images.filter(item => item.id !== id))
                TOASTS.success('Image deleted successfully')
            }else{
                TOASTS.error('Oups, an error as occured while deleting image...')
            }
        }catch(e){
            console.error(e)
            TOASTS.error('Oups, an error as occured while deleting image...')
        }
    }

    const handleReplaceCover = async(e)=>{
        e.preventDefault()
        try{
            const formData = new FormData()
            formData.append('project_cover', e.target.files[0])
            const response = await API_FUNCTIONS.replaceProjectCover(formData, project.id, token)

            if(response.status === 200){
                TOASTS.success('Cover replaced successfully')
                setProject({...project, project_cover: response.data.data.project_cover, cover_urls: response.data.data.cover_urls})
            }else{
                TOASTS.error('Oups, an error as occured while replacing cover')
            }
        }catch(e){
            TOASTS.error('Oups, an error as occured while replacing cover')
            console.error(e)
        }
    }

    const updateProject = async()=> {
        try{
            const response = await API_FUNCTIONS.updateProject(editContent, token)
            if(response.status === 200){
                TOASTS.success('Project updated successfully')
                const updatedProject = {
                    ...project,
                    project_date: response.data.data.project_date,
                    project_en_synopsis: response.data.data.project_en_synopsis,
                    project_fr_synopsis: response.data.data.project_fr_synopsis,
                    project_en_description: response.data.data.project_en_description,
                    project_fr_description: response.data.data.project_fr_description,
                    project_github: response.data.data.project_github,
                    project_link: response.data.data.project_link,
                    project_is_favorite: response.data.data.project_is_favorite,
                }
                setProject(updatedProject)
                setEditContent(updatedProject)
                setIsEditingProject(false)
            }else{
                TOASTS.error('Oups, an error as occured while updating project')
            }
        }catch(e){
            TOASTS.error('Oups, an error as occured while updating project')
            console.error(e)
        }
    }
    
    const deleteProject = async() => {
        try{
            const response = await API_FUNCTIONS.deleteProject(project.id, token)
            if(response.status === 200){
                navigate('/admin')
            }else{
                TOASTS.error('Oups, an error as occured while deleting project')
            }
        }catch(e){
            TOASTS.error('Oups, an error as occured while deleting project')
            console.error(e)
        }
    }

  return (
    isLoaded && 
    <div className='mainContainer projectPreview'>
        <FontAwesomeIcon icon={faSquareCaretLeft} onClick={() => navigate('/admin')} className='projectPage__return'/>
        {isEditingProject && <button className='projectPreview__save' onClick={() => updateProject()}>Save updates</button>}
        <p className='projectPreview__warning'>Project preview</p>
        <h1>{project.project_name}</h1>
        <input type='date' value={editContent.project_date} onChange={e => setEditContent({...editContent, project_date: e.target.value})}/>
        
        <img src={API_STORAGE+project.cover_urls['thumb-webp']} alt='cover preview' width={150}/>
        <label>Replace cover</label>
        <input type='file' accept='images/*' onChange={(e) => handleReplaceCover(e)}/>
        <article className='adminProjects__form'>
            <h2>English version</h2>
            <div className='projectPreview__content'>
                <p>Synopsis</p>
                {/* <input type="text" value={editContent.project_en_synopsis} onChange={e => setEditContent({...editContent, project_en_synopsis: e.target.value})}/> */}
                <textarea maxLength={150} onChange={e => setEditContent({...editContent, project_en_synopsis: e.target.value})}>
                    {editContent.project_en_synopsis}
                </textarea>
                {<span className={150 - editContent.project_en_synopsis.length < 0 ? 'danger' : ''}>{150 - editContent.project_en_synopsis.length}</span>}

                <p>Content</p>
                <ReactQuill value={editContent.project_en_description} onChange={e => setEditContent({...editContent, project_en_description: e})} />
            </div>
            
            <h2>Version française</h2>
            <div className='projectPreview__content'>
                <p>Synopsis</p>
                {/* <input type="text" value={editContent.project_fr_synopsis} onChange={e => setEditContent({...editContent, project_fr_synopsis: e.target.value})}/> */}
                <textarea maxLength={150} onChange={e => setEditContent({...editContent, project_fr_synopsis: e.target.value})}>
                    {editContent.project_fr_synopsis}
                </textarea>
                {<span className={150 - editContent.project_fr_synopsis.length < 0 ? 'danger' : ''}>{150 - editContent.project_fr_synopsis.length}</span>}

                <p>Contenu</p>
                <ReactQuill value={editContent.project_fr_description} onChange={e => setEditContent({...editContent, project_fr_description: e})} />

            </div>
            
            <h2>Informations</h2>

            <label>Github</label>
            <input type="text" value={editContent.project_github} onChange={e => setEditContent({...editContent, project_github: e.target.value})} />

            <label>Link</label>
            <input type="text" value={editContent.project_link} onChange={e => setEditContent({...editContent, project_link: e.target.value})}/>


            <h2>Skills</h2>
            <div className='projectPreview__skills'>
                {skills.sort((a,b) => b.pivot.is_favorite - a.pivot.is_favorite).map(skill => 
                    <div key={skill.id} className='projectPreview__skills__item'>
                        {skill.pivot.is_favorite ? 
                            <FontAwesomeIcon icon={faStar} onClick={() => handleToggleFavoriteSkill(skill)} className='adminProjects__form__skillList__selected__star'/>
                        :
                            <FontAwesomeIcon icon={faEmptyStar} onClick={() => handleToggleFavoriteSkill(skill)} className='adminProjects__form__skillList__selected__emptyStar'/>
                        }
                        {skill.skill_en_name}
                        <FontAwesomeIcon icon={faCircleXmark} onClick={() => handleRemoveSkill(skill.id)} className='adminProjects__form__skillList__selected__remove'/>
                    </div>
                )}
                {allSkills.map(skill => 
                    !skills.find(item => item.id === skill.id) && <div key={skill.id} className='adminProjects__form__skillList__item' onClick={() => {handleAddSkill(skill)}}>
                        {skill.skill_en_name}
                    </div>
                )}
            </div>

            <h2>Images</h2>
            {imageFormOpened ? <button onClick={() => setImageFormOpened(false)}>Cancel</button> : <button onClick={() => setImageFormOpened(true)}>Add Image</button>}
            {imageFormOpened && 
                <form>
                    <input type="file" accept="image/*" onChange={(e) => setNewImage({...newImage, image_name: e.target.files[0]})} required/>

                    <label>English Alt</label>
                    <input type='text' onChange={e => setNewImage({...newImage, image_en_alt: e.target.value})} required/>

                    <label>Franch Alt</label>
                    <input type='text' onChange={e => setNewImage({...newImage, image_fr_alt: e.target.value})} required/>
                     
                    <button onClick={(e) => handleAddImage(e)}>Send</button>
                </form>
            }
            <div className='adminProjects__form__imagesPreview'>
                {
                    images.sort((a, b) => a.image_index - b.image_index).map(image => 
                        <div key={image.id} className='adminProjects__form__imagesPreview__item'>
                        <img src={API_STORAGE+image.urls['thumb-webp']} alt={image.image_en_alt} width={100} onClick={() => {setUpdatingImage(image); openContent('updateImageModal')}}/>
                        <div>
                          <span>{(image.image_index > 0 && project.images.length>1) &&<FontAwesomeIcon icon={faMinus} onClick={() => handleMoveImageUp(image)}/>}</span>
                          <span onClick={() => handleDeleteImage(image.id)}><FontAwesomeIcon icon={faTrash} /></span>
                          <span>{(image.image_index < project.images.length-1 && project.images.length > 1) && <FontAwesomeIcon icon={faPlus} onClick={() => handleMoveImageDown(image)}/>}</span>
                        </div>
                      </div>
                    )
                }
            </div>


        </article>

        <button onClick={() => deleteProject()}>Delete project</button>

        <UpdateImage image={updatingImage} handleUpdateImage={handleUpdateImage}/>
    </div>
  )
}

export default AdminProjectPreview