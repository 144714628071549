import { API_FUNCTIONS } from "./apiFunctions"

export const setDefaultTheme = () => {
  if(localStorage.getItem('theme')){
    document.body.className = (localStorage.getItem('theme'))
  }else{
    if(window.matchMedia('(prefers-color-scheme: dark)').matches===true){
      document.body.className = "dark"
      localStorage.setItem('theme', "dark")
    }else{
      document.body.className = "light"
      localStorage.setItem('theme', "light")
    }
  }
}

export const changeTheme = (setTheme) => {

  if (document.body.className === 'light' || (!document.body.className &&  window.matchMedia('(prefers-color-scheme: dark)')).matches===false ) {
    document.body.className = 'dark'
    localStorage.setItem('theme', 'dark')
    setTheme('dark')
  } else {
    document.body.className = 'light'
    localStorage.setItem('theme', 'light')
    setTheme('light')
  }

}

export const autoScroll = () => {
  if (window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export const isTokenExpired = (token) => {
  const tokenData = JSON.parse(atob(token.split('.')[1]))
  const expirationTime = tokenData.exp * 1000
  return Date.now() >= expirationTime
}


export const openContent = (id) => {
  document.getElementById(id).style.display = "flex"
}

export const closeContent = (id) => {
  document.getElementById(id).style.display = "none"
}

export const clickOutModal = (e, id) => {
  if(e.target.attributes.id){
      if(e.target.attributes.id.textContent === id){
          closeContent(id)
      }
  }
}

export const listenEscapeKey = (id)=>{
  document.addEventListener('keydown', (e)=>{
    if(e.key === "Escape"){
          closeContent(id)
      }
  })
}