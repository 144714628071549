import React from 'react'

function Separator() {
  return (
    <div className='menu__container__separator'>

    </div>
  )
}

export default Separator