import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { API_STORAGE } from '../utils/apiFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { fr, enUS } from 'date-fns/locale'

import { GlobalContext } from '../contexts/GlobalContext'

function ProjectItem({project}) {
    const {language} = useContext(GlobalContext)

    const formatDate = (initialDate) => {
        const formatedDate = new Date(initialDate)
        return format(formatedDate, 'MMMM yyyy', {locale: language === 'fr' ? fr : enUS})
      }

    return (
        <Link to={'/projects/'+project.project_slug} className='projectItem'>
            <div className="projectItem__image" >
                {project.project_is_favorite ? <FontAwesomeIcon icon={faStar} className='projectItem__image__star'/> : ''}
                {/* <img src={API_STORAGE+project.cover_urls['thumb-webp']} alt={"Couverture du projet "+project.name}/> */}
                <picture>
                    <source 
                    srcSet={`
                        ${API_STORAGE + project.cover_urls['small-webp']} 680w,
                        ${API_STORAGE + project.cover_urls['medium-webp']} 990w,
                        ${API_STORAGE + project.cover_urls['large-webp']} 1200w
                    `}
                    sizes="(max-width: 680px) 100vw,
                            (max-width: 990px) 100vw,
                            220px"
                    type="image/webp"
                    />

                    <source 
                    srcSet={`
                        ${API_STORAGE + project.cover_urls['small-jpg']} 680w,
                        ${API_STORAGE + project.cover_urls['medium-jpg']} 990w,
                        ${API_STORAGE + project.cover_urls['large-jpg']} 1200w
                    `}
                    sizes="(max-width: 680px)100vw,
                            (max-width: 990px)100vw,
                            220px"
                    type="image/jpeg"
                    />
                    
                    <img 
                    src={API_STORAGE + project.cover_urls['large-webp']} 
                    alt={language === 'fr' ? 'Couverture du projet' : 'Project cover'}
                    />
                </picture>
            </div>

            <div className='projectItem__content'>
                <h3 className='projectItem__content__title'>{project.project_name}</h3>
                <span className='projectItem__content__date'>{formatDate(project.project_date)}</span>
                <p className='projectItem__content_description'>{project.project_en_synopsis}</p>
                <div className='projectItem__content__skills'>
                    {
                        project.skills?.filter(item => item.pivot.is_favorite).slice(0,3).map((skill, index) => 
                        <div key={index} className='projectItem__content__skills__item'>{skill.skill_en_name}</div>
                        )
                    }
                    {project.skills.length > 3 && <div className='projectItem__content__skills__item'>...</div>}
                </div>
            </div>
        </Link>
    )
}

export default ProjectItem