import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

function ProjectImageAdd({newImage, handleUpdateAddedImages}) {
  const [enAlt, setEnAlt] = useState('')
  const [frAlt, setFrAlt] = useState('')
  
  const setImageInformations = (e) => {
    e.preventDefault()
    const updated = {
      ...newImage,
      image_en_alt: enAlt,
      image_fr_alt: frAlt,
    }
    handleUpdateAddedImages(updated)
  }

  useEffect(() =>{
    if(newImage.image_en_alt){
      setEnAlt(newImage.image_en_alt)
    }else{
      setEnAlt('')
    }

    if(newImage.image_fr_alt){
      setFrAlt(newImage.image_fr_alt)
    }else{
      setFrAlt('')
    }
  }, [newImage])



  /**
   * Modal Specific Comportement
   */

  const clickOutModal = (e, id) => {
    if(e.target.attributes.id){
        if(e.target.attributes.id.textContent === id){
            closeContent(id)
            handleUpdateAddedImages(newImage)
        }
    }
  }

  const closeContent = (id) => {
    document.getElementById(id).style.display = "none"
    handleUpdateAddedImages(newImage)
  }

  return (
    <div className='modal' id='addProjectImageInformationModal' onClick={(e) => clickOutModal(e, 'addProjectImageInformationModal')}>
        <div className='modal__content'>
            <FontAwesomeIcon icon={faXmark} className='modal__content__close' onClick={() => closeContent('addProjectImageInformationModal')}/>
            <p className='modal__content__title'>Add Images Informations</p>

            <img src={newImage.preview} alt='preview' width={200}/>


            <form onSubmit={e => setImageInformations(e)} className='modalForm'>
                <label>English Alt</label>
                <input type="text" value={enAlt} onChange={e => setEnAlt(e.target.value)} required/>
                
                <label>Frech Alt</label>
                <input type="text" value={frAlt} onChange={e => setFrAlt(e.target.value)} required/>

                <button type="submit">Save</button>
                
            </form>

        </div>
    </div>
  )
}

export default ProjectImageAdd