import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


import avatar from '../assets/images/avatar.jpg'
import codingame from '../assets/icons/codingame.svg'

import ScrollEffect from '../containers/ScrollEffect'

import SocialButton from './SocialButton'



function LayoutWelcome() {
  const [t] = useTranslation("global")

 
  return (
    <div className='welcome' id="welcome">
      <ScrollEffect>
        <div className='welcome__desc'>
          <p>{t('welcome.hello')}</p>
          <h1 className='welcome__desc__name'>Yann Ghistelinck</h1>
          <h2>{t("welcome.status")}</h2>
          <div className='welcome__desc__social'>
            <Link to="https://www.linkedin.com/in/yann-ghistelinck/" target="blank" className='welcome__desc__social__button'>
              <FontAwesomeIcon icon={faLinkedin} className='welcome__desc__social__button__icon'/>
              <p>LinkedIn</p>
            </Link>
            <Link to="https://github.com/YannGhistelinck" target="blank" className='welcome__desc__social__button'>
              <FontAwesomeIcon icon={faSquareGithub} className='welcome__desc__social__button__icon'/>
              <p>Github</p>
            </Link>
            <Link to="https://www.codingame.com/profile/a68fa56cd54100d7cf33ca2e2af5bb9f1296884" target="blank" className='welcome__desc__social__button'>
              <img src={codingame} alt="CodinGame" className='welcome__desc__social__button__icon'/>
              <p>CodinGame</p>
            </Link>
            <a href="#contact" className='welcome__desc__social__button'>
            <FontAwesomeIcon icon={faEnvelope} className='welcome__desc__social__button__icon'/>
              <p>{t('welcome.contactButton')}</p>
            </a>
          </div>
        </div>

      </ScrollEffect>
      
      

      <img src={avatar} alt="Yann Ghistelinck" className='welcome__img'/>
      
    </div>
  )
}

export default LayoutWelcome