import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { API_STORAGE } from '../utils/apiFunctions'
import { GlobalContext } from '../contexts/GlobalContext'



function ProjectCard({project}) {
  const {language} = useContext(GlobalContext)
  return (
    // <div className='projectCard'>
    //     <img src={require(`../assets/images/${project.cover}`)} className="projectCard__img" alt={"Couverture du projet "+project.name}/>
    //     <div className='projectCard__layout'></div>
    //     <div className='projectCard__skills'>
    //         {
    //             project.skills.slice(0,3).map((skill, index) => 
    //             <div key={index} className='projectCard__skills__item'>{skill}</div>
    //             )
    //         }
    //     </div>
    //     <p className='projectCard__name'>{project.name}</p>
    //     <p className='projectCard__description'>{project.description[0].slice(0,150)} [...]</p>
    // </div>

    // <Link to={'/projects/'+project.project_slug} >
    <Link to={(language === 'fr' ? '/projets/' : '/projects/')+project.project_slug} >
      <div className='projectCard'>
        <img src={API_STORAGE+project.cover_urls['thumb-webp']} className="projectCard__img" alt={"Couverture du projet "+project.name}/>
        <div className='projectCard__layout'></div>
        <div className='projectCard__skills'>
            {
                project.skills?.filter(item => item.pivot.is_favorite).slice(0,3).map((skill, index) => 
                <div key={index} className='projectCard__skills__item'>{skill.skill_en_name}</div>
                )
            }
        </div>
        <p className='projectCard__name'>{project.project_name}</p>
        <p className='projectCard__description'>{project.project_en_synopsis}</p>

      </div>
    </Link>
  )
}

export default ProjectCard