import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/GlobalContext';

import ReactQuill, {Quill} from 'react-quill'
// import ImageUploader from 'quill-image-uploader';
import 'react-quill/dist/quill.snow.css';
import { API_FUNCTIONS, API_STORAGE } from '../../utils/apiFunctions';
import { TOASTS } from '../../utils/toasts';
// import 'quill-image-uploader/dist/quill.imageUploader.min.css';

// Quill.register('modules/imageUploader', ImageUploader);

import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faSpinner, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import ProjectImageAdd from '../modals/ProjectImageAdd';
import { openContent } from '../../utils/functions';
import { faCircleXmark, faStar as faEmptyStar } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

function AdminProjects() {
  const {token} = useContext(GlobalContext)
  const{register, handleSubmit, formState: {errors}, reset, watch, setValue} = useForm()

  const [enContent, setEnContent] = useState('')
  const [frContent, setFrContent] = useState('')
  const [images, setImages] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [projects, setProjects] = useState([])
  const [ currentCoverImage, setCurrentCoverImage] = useState(null)
  const [imagesAdded, setImagesAdded] = useState([])
  const [skills, setSkills] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [favoriteSkillsCounter, setFavoriteSkillsCounter] = useState(0)


  /**
   * INITIAL LOADING
   */
  const loadingDatas = async() => {
    try{
      
      const [projectDatas, SkillDatas] = await Promise.all([
        API_FUNCTIONS.allProjects('admin'),
        API_FUNCTIONS.allSkills()
      ])

      if(projectDatas.status === 200){
        setProjects(projectDatas.data.data)
      }else{
        TOASTS.error('Oups, an error as occured while loading projects...')
      }
      
      if(SkillDatas.status === 200){
        setSkills(SkillDatas.data.data)
      }else{
          TOASTS.error('Oups, an error as occured while loading skills...')
      }

    }catch(e){
      TOASTS.error('Error')
    }finally{
      setIsLoaded(true)
    }
  }

  const loadingFormDataFromLocalStorage = () => {
    const savedFormData = localStorage.getItem('newProjectContent');
    if (savedFormData) {
      const formData = JSON.parse(savedFormData);
      Object.keys(formData).forEach(key => {
        if (key === 'project_fr_description') {
          setFrContent(formData[key]);
        } else if (key === 'project_en_description') {
          setEnContent(formData[key]);
        } else {
          setValue(key, formData[key]);
        }
      });
    }

    const imagesFromLocalStorage = localStorage.getItem('newProjectImages')
    if(imagesFromLocalStorage){
      const imagesToStore = JSON.parse(imagesFromLocalStorage)
      
      setImages(imagesToStore)
    }

    const coverImageToSave = localStorage.getItem('newProjectCurrentCoverImage')
    if(coverImageToSave) setCurrentCoverImage(JSON.parse(coverImageToSave))
  }

  useEffect(() => {
    if(!isLoaded){
      loadingDatas()
      loadingFormDataFromLocalStorage()
    }
  }, [])


  /**
   *  HANDLE FUNCTIONS
   */
  
  const handleCoverUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setCurrentCoverImage([reader.result]);
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = (e) => {
    const fileList = Array.from(e.target.files)
    const newImages = []

    const promises = fileList.map((file, index) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImages.push({ file: file, preview: reader.result, index: images.length + index });
          resolve();
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });
  
    Promise.all(promises)
      .then(() => {
        setImages(prev => [...prev, ...newImages]);
        setImagesAdded(newImages);
      })
      .catch(error => {
        console.error('Error reading files:', error);
      });
  };

  useEffect(() => {
    if(imagesAdded.length > 0){
      openContent('addProjectImageInformationModal')
    }
  }, [imagesAdded])

  const handleUpdateAddedImages = (updates) =>{ 
    const updatedImages = images.map(image => 
      image.index === updates.index ? 
        updates
      :
        image
    )
    setImages(updatedImages)
    setImagesAdded(prev => prev.filter(item => item.index !== updates.index))
  }

  const handleRemoveImage = (imageName) => {
    const indexRemoved = images.find(item => item.file.name === imageName).index
    const updatedImages = images.map(image => 
      image.index > indexRemoved ? 
        {
          ...image,
          index: image.index-1
        }
      :
        image
    )
    // setImages(prev => [...prev.filter(item => item.file.name !== imageName)]) 
    setImages([...updatedImages.filter(item => item.file.name !== imageName)]) 
  }

  const handleMoveUpImage = (index) => {
    if (index > 0){
      let imageToMove = images.find(item => item.index === index)
      let secondImageToMove = images.find(item => item.index === index-1)
      imageToMove = {
        ...imageToMove,
        index: index-1
      }
      secondImageToMove = {
        ...secondImageToMove,
        index: index
      }
      
      const updatedImages = images.map(image => 
        image.index === index ? 
          imageToMove
        : 
          image.index === index-1 ? 
            secondImageToMove
          :
            image
      )
      setImages(updatedImages.sort((a, b) => a.index - b.index))
    }

  }

  const handleMoveDownImage = (index) => {
    if (index < images.length-1){
      let imageToMove = images.find(item => item.index === index)
      let secondImageToMove = images.find(item => item.index === index+1)
      imageToMove = {
        ...imageToMove,
        index: index+1
      }
      secondImageToMove = {
        ...secondImageToMove,
        index: index
      }
      
      const updatedImages = images.map(image => 
        image.index === index ? 
          imageToMove
        : 
          image.index === index+1 ? 
            secondImageToMove
          :
            image
      )
      setImages(updatedImages.sort((a, b) => a.index - b.index))
    }
  }

  

  const handleLocalSave = async(data) => {

    const formData = {
      ...data,
      project_fr_description: frContent,
      project_en_description: enContent
    };


    localStorage.setItem('newProjectContent', JSON.stringify(formData))
    localStorage.setItem('newProjectImages', JSON.stringify(images))
    localStorage.setItem('newProjectCurrentCoverImage', JSON.stringify(currentCoverImage))

    TOASTS.success('Work saved in local session')
  }

  const handleLocalDestroy = () => {
    reset()
    setImages([])
    setImagesAdded([])
    setCurrentCoverImage(null)
    setFrContent('')
    setEnContent('')
    localStorage.removeItem('newProjectContent')
    localStorage.removeItem('newProjectImages')
    localStorage.removeItem('newProjectCurrentCoverImage')
    TOASTS.success('Work destroyed from local session')
  }

  const handleSelectSkill = (id) => {
    const selected = skills.find(item => item.id === id)
    setSelectedSkills(prev => [...prev, {...selected, project_main_skill: 0}])
    setSkills(skills.filter(item => item.id !== id))
  }

  const handleRemoveSkill = (id) => {
    const skill = selectedSkills.find(item => item.id === id)
    const toRemove = {
      id: skill.id,
      skill_en_name: skill.skill_en_name,
      skill_fr_name: skill.skill_fr_name,
      skill_is_favorite: skill.skill_is_favorite
    }
    setSelectedSkills(selectedSkills.filter(item => item.id !== id))
    setSkills(prev => [...prev, toRemove])
  }

  const handleMakeFavoriteSkill = (id) => {
    const skill = selectedSkills.find(item => item.id === id)
    const updated = {
      ...skill,
      project_main_skill: 1
    }
    const updatedSkills = selectedSkills.map(sk => 
      sk.id === id ? 
      updated
      :
      sk
    )
    setSelectedSkills(updatedSkills)
    setFavoriteSkillsCounter(favoriteSkillsCounter+1)
  }

  const handleRemoveFavoriteSkill = (id) => {
    const skill = selectedSkills.find(item => item.id === id)
    const updated = {
      ...skill,
      project_main_skill: 0
    }
    const updatedSkills = selectedSkills.map(sk =>
      sk.id === id ?
      updated
      :
      sk
    )
    setSelectedSkills(updatedSkills)
    setFavoriteSkillsCounter(favoriteSkillsCounter-1)
  }

  const handleToggleProjectFavorite = async(e, project)=>{
    e.preventDefault()
    try{
      const response = await API_FUNCTIONS.projectToggleFavorite(project.id, token)
      if(response.status === 200){
        TOASTS.success('Project add successfully to favorites')
        const updatedProjects = projects.map(p =>
          p.id === project.id ? 
            {...project, project_is_favorite: project.project_is_favorite ? 0:1}
          :
            p
        )
        setProjects(updatedProjects)
      }else{
        TOASTS.error('Oups, an error as occured while toggle favorite project status...')
      }
    }catch(e){
      TOASTS.error('Oups, an error as occured while toggle favorite project status...')
      console.error(e)
    }
  }

  

  /**
   *  API FUNCTIONS
   */

  const addProject = async(data)=>{
    setIsSending(true)
  
    const formData = new FormData()
    formData.append('project_cover', data.project_cover[0])
    formData.append('project_name', data.project_name)
    formData.append('project_slug', data.project_slug)
    formData.append('project_date', data.project_date.split(' ')[0])
    formData.append('project_en_synopsis', data.project_en_synopsis)
    formData.append('project_fr_synopsis', data.project_fr_synopsis)
    formData.append('project_fr_description', frContent)
    formData.append('project_en_description', enContent)
    formData.append('project_github', data.project_github)
    formData.append('project_link', data.project_link)
    formData.append('project_is_published', 0)
    formData.append('project_is_favorite', 0)
    images.forEach((image, index) => {    
      formData.append(`images[${index}][image_name]`, image.file);
      formData.append(`images[${index}][image_en_alt]`, image.image_en_alt);
      formData.append(`images[${index}][image_fr_alt]`, image.image_fr_alt);
      formData.append(`images[${index}][image_index]`, image.index);
    });
    selectedSkills.forEach((skill, index) => {
      formData.append(`skills[${index}][id]`, skill.id)
      formData.append(`skills[${index}][is_favorite]`, skill.project_main_skill)
    })

    try{
      const response = await API_FUNCTIONS.addProject(formData, token)
      if(response.status === 201){
        TOASTS.success('Project created successfully')
        handleLocalDestroy()
      }else{
        TOASTS.error('Oups, an error as occured while creating project...')
      }
    }catch(e){
      TOASTS.error('Oups, an error as occured while creating project...')
    }finally{
      setIsSending(false)
    }
  }

  const publishProject = async(e, id) =>{
    e.preventDefault()
    try{
      const response = await API_FUNCTIONS.publishProject(id, token)

      if(response.status === 200){
        TOASTS.success('Project published successfully')
        const updatedProjects = projects.map(project => 
          project.id === id ? 
            response.data.data
          :
            project
        )

        setProjects(updatedProjects)
      }else{
        TOASTS.error('Oups, an error as occured while publishing project...')
      }
    }catch(e){
      console.error(e)
      TOASTS.error('Oups, an error as occured while publishing project...')
    }
  }

  const unpublishProject = async(e, id) => {
    e.preventDefault()
    try{
      const response = await API_FUNCTIONS.unpublishProject(id, token)

      if(response.status === 200){
        TOASTS.success('Project unpublished successfully')
        const updatedProjects = projects.map(project => 
          project.id === id ? 
            response.data.data
          :
            project
        )

        setProjects(updatedProjects)
        
      }else{
        TOASTS.error('Oups, an error as occured while unpublishing project...')
      }
    }catch(e){
      console.error(e)
      TOASTS.error('Oups, an error as occured while unpublishing project...')
    }
  }


  return (
    isLoaded &&
    <div className='adminProjects mainContainer'>
        <h2>Projects</h2>
        <h3>New project</h3>

        <form onSubmit={handleSubmit(addProject)} className='adminProjects__form'>
          {/* <span className='btn btn--success' onClick={() => handleLocalSave()}>Local Save</span> */}
          <button type="button" onClick={handleSubmit(handleLocalSave)}>Save to Local Storage</button>
          <span className='btn btn--danger' onClick={() => handleLocalDestroy()}>Destroy Draft</span>

          <label htmlFor='newProjectName'>Project name</label>
          <input type='text' id="newProjectName" {...register('project_name')}/>

          <label htmlFor='newProjectSlug'>Project slug</label>
          <input type='text' id="newProjectSlug" {...register('project_slug')}/>

          <label htmlFor='newProjectDate'>Date</label>
          <input type="date" {...register('project_date')}/>

          <label htmlFor='newProjectCover'>Project Cover (16:9 format)</label>
          <input type="file" id='newProjectCover' {...register('project_cover')} onChange={handleCoverUpload}/>
          <div>
            {currentCoverImage &&
              <div>
                <img src={currentCoverImage} alt="preview" width="100"/>
                <span onClick={() => {setValue('project_cover', null); setCurrentCoverImage(null)}}><FontAwesomeIcon icon={faTrash} /></span>
              </div>
            }
          </div>


          <h4>English</h4>
          <label htmlFor='newProjectEnSynopsis'>Synopsis</label>
          <input type="text" id='newProjectEnSynopsis' {...register('project_en_synopsis')}/>

          <ReactQuill value={enContent} onChange={setEnContent} />

          <h4>Français</h4>
          <label htmlFor='newProjectFrSynopsis'>Synopsis</label>
          <input type="text" {...register('project_fr_synopsis')}/>
          <ReactQuill value={frContent} onChange={setFrContent} />

          <h4>Informations</h4>

          <label htmlFor='newProjectGithub'>Github</label>
          <input type="text" id='newProjectGithub' {...register('project_github')}/>

          <label htmlFor='newProjectLink'>Link</label>
          <input type="text" id='newProjectLink' {...register('project_link')}/>

          <label>Skills</label>
          <div className='adminProjects__form__skillList'>
            {
              selectedSkills.sort((a, b) => b.project_main_skill - a.project_main_skill).map(skill => 
                <div key={skill.id} className='adminProjects__form__skillList__selected'>
                  {skill.project_main_skill ? 
                  <FontAwesomeIcon icon={faStar} onClick={() => handleRemoveFavoriteSkill(skill.id)} className='adminProjects__form__skillList__selected__star'/>
                  :
                  <FontAwesomeIcon icon={faEmptyStar} onClick={() => handleMakeFavoriteSkill(skill.id)} className='adminProjects__form__skillList__selected__emptyStar'/>
                  }
                  {skill.skill_en_name}
                  <FontAwesomeIcon icon={faCircleXmark} onClick={() => handleRemoveSkill(skill.id)} className='adminProjects__form__skillList__selected__remove'/>
                </div>
              )
            }
            {skills.map(skill => 
              <div key={skill.id} className='adminProjects__form__skillList__item' onClick={() => {handleSelectSkill(skill.id)}}>
                {skill.skill_en_name}
              </div>
            )}
          </div>

          <label htmlFor='newProjectImages'>Images</label>
          <input type="file" id="newProjectImages" name="images" accept="image/*" onChange={handleImageUpload} multiple/>
          <div className='adminProjects__form__imagesPreview'>
            {images.sort((a, b) => a.index - b.index).map((image, index) => (
              <div key={index} className='adminProjects__form__imagesPreview__item'>
                <img src={image.preview} alt="preview" width="100" onClick={() => setImagesAdded(prev => [...prev, image])}/>
                <div>
                  <span>{(index > 0 && images.length>1) &&<FontAwesomeIcon icon={faMinus} onClick={() => handleMoveUpImage(index)}/>}</span>
                  <span onClick={() => handleRemoveImage(image.file.name)}><FontAwesomeIcon icon={faTrash} /></span>
                  <span>{(index < images.length-1 && images.length > 1) && <FontAwesomeIcon icon={faPlus} onClick={() => handleMoveDownImage(index)}/>}</span>
                </div>
              </div>
            ))}
          </div>
          

          {isSending ? <button disabled><FontAwesomeIcon icon={faSpinner} className='spinner'/></button> : <button type="submit">Create</button>}
        </form>

        <h3>My projects</h3>

        <div className='adminProjects__projects'>
          {
            projects.sort((a, b) => b.project_is_published - a.project_is_published).map(project => 
              <Link to={'/admin/'+project.project_slug} key={project.id} className={'adminProjects__projectCard ' + (project.project_is_published === 0 ? 'adminProjects__projectCard--draft' : '')}> 
                <div>
                  {project.project_is_favorite ? 
                      <FontAwesomeIcon icon={faStar} onClick={(e) => handleToggleProjectFavorite(e, project)} className='adminProjects__form__skillList__selected__star'/>
                    :
                      <FontAwesomeIcon icon={faEmptyStar} onClick={(e) => handleToggleProjectFavorite(e, project)} className='adminProjects__form__skillList__selected__emptyStar'/>
                    }
                  {project.project_is_published ? 
                    <button onClick={(e) => unpublishProject(e, project.id)}>Unpublish</button>
                    :
                    <button onClick={(e) => publishProject(e, project.id)}>Publish</button>
                  }
                  <p>{project.project_name}</p>
                  <p>{project.project_date}</p>
                  <p>{project.project_en_synopsis}</p>
                  <img src={API_STORAGE+project.cover_urls['thumb-webp']} alt={project.project_name} width='100'/>
                  {/* <p dangerouslySetInnerHTML={{ __html: project.project_en_description }}></p> */}
                </div>
              </Link>
            )
          }
        </div>
        {/* <h4>Drafts</h4>
        <h4>Published</h4> */}

        {imagesAdded.length >0 && <ProjectImageAdd newImage={imagesAdded[0]} handleUpdateAddedImages={handleUpdateAddedImages}/>}

    </div>
  )
}

export default AdminProjects