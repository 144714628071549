import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import { TOASTS } from '../utils/toasts'

import { GlobalContext } from '../contexts/GlobalContext'
import { AdminContext } from '../contexts/AdminContext'

import AdminMenu from '../components/admin/AdminMenu'
import AdminProjects from '../components/admin/AdminProjects'
import AdminSkills from '../components/admin/AdminSkills'
import AdminParameters from '../components/admin/AdminParameters'
import AdminSkillCategories from '../components/admin/AdminSkillCategories'

function Admin() {
    const navigate = useNavigate()
    const {token, setToken} = useContext(GlobalContext)

    const [adminUrl, setAdminUrl] = useState(localStorage.getItem('adminUrl') || 'projects')
    const [isTokenValid, setIsTokenValid] = useState(false)

    useEffect(() => {
      checkUser()

      if(!token){
        navigate('/login')
      }
    }, [token])

    const logout = async()=>{
      API_FUNCTIONS.logout(token)
      localStorage.removeItem('token')
      setToken(null)
      navigate('/')
    }

    const checkUser = async()=>{
      const checkUser = await API_FUNCTIONS.currentUser(token)
      if(checkUser.status !== 200){
        localStorage.removeItem('token')
        setToken(null)
      }else{
        setIsTokenValid(true)
      }
    }

  return (
    <AdminContext.Provider value={{adminUrl, setAdminUrl}}>
      {
        isTokenValid &&

        <div className=''>
          <h1>Manager Area</h1>

          <button onClick={() => logout()}>Déconnexion</button>

          <Link to="/">Home</Link>

          <AdminMenu/>

          {adminUrl === 'projects' && <AdminProjects/>}
          {adminUrl === 'skills' && <AdminSkills/>}
          {adminUrl === 'skillCategories' && <AdminSkillCategories/>}
          {adminUrl === 'parameters' && <AdminParameters/>}
        </div>
      }
    </AdminContext.Provider>
  )
}

export default Admin