import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/GlobalContext'
import { openContent } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'
import SkillAdd from '../modals/SkillAdd'
import SkillUpdate from '../modals/SkillUpdate'
import { faStar } from '@fortawesome/free-solid-svg-icons'

function AdminSkills() {
  const {token} = useContext(GlobalContext)

  const [isLoaded, setIsLoaded] = useState(false)
  const [skills, setSkills] = useState([])
  const [skillCategories, setSkillCategories] = useState([])
  const [updatingSkill, setUpdatingSkill] = useState(null)
  const [skillFilter, setSkillFilter] = useState(0)

  const loadingDatas = async() => {
    try{
      const [skillsData, SkillCategoriesData] = await Promise.all([
        API_FUNCTIONS.allSkills(),
        API_FUNCTIONS.allSkillCategories()
      ]) 

      if(skillsData.status === 200){
        setSkills(skillsData.data.data)
      }else{
        TOASTS.error('Oups, an error as occured while loading skills...')
      }

      if(SkillCategoriesData.status === 200){
        setSkillCategories(SkillCategoriesData.data.data)
      }else{
        TOASTS.error('Oups, an error as occured while loading skill categories...')
      }


    }catch(e){
      TOASTS.error('Oups, something went wrong while loading data...')
    }finally{
      setIsLoaded(true)
    }
  }

  useEffect(() => {
    if(!isLoaded){
      loadingDatas()
    }
  }, [])

  const handleAddSkill = (newSkill) => {
    setSkills(prev => [...prev, newSkill])
  }

  const handleUpdateSkill = (updatedSkill) => {
    const updatedSkills = skills.map(skill => 
      skill.id === updatedSkill.id ?
        updatedSkill
        :
        skill
    )
    setSkills(updatedSkills)
  }

  const handleDeleteSkill = (skill) => {
    setSkills(prev => prev.filter(item => item.id !== skill.id)) 
  }


  return (
    <div className='mainContainer adminSkills'>
      <h2>Skills</h2>

      {
        isLoaded &&
        <>
          <div className='adminSkills__filter'>
            <div className={skillFilter === 0 ? 'adminSkills__filter__item adminSkills__filter__item--selected' : 'adminSkills__filter__item'} onClick={() => setSkillFilter(0)}>ALL</div>
            {
              skillCategories.map(cat => 
                <div key={cat.id} className={skillFilter === cat.id ? 'adminSkills__filter__item adminSkills__filter__item--selected' : 'adminSkills__filter__item'} onClick={() => setSkillFilter(cat.id)}>{cat.skillCategory_en_name}</div>
              )
            }
          </div>

          <div className='adminSkills__list'>
            <button className='adminSkills__list__createBtn' onClick={() => openContent('addSkillModal')}>Create</button>
            {skills
            .filter(item => skillFilter === 0 || item.skill_category_id === skillFilter)
            .sort((a, b) => b.skill_is_favorite - a.skill_is_favorite)
            .map(skill => 
              <div key={skill.id} className='adminSkills__list__item' onClick={() => {setUpdatingSkill(skill); openContent('updateSkillModal')}} tabIndex={0}>
                  {skill.skill_en_name} 
                  {skill.skill_is_favorite ? <FontAwesomeIcon icon={faStar} className='favoriteStar'/> : null}
              </div>

            )}
          </div>

          <SkillAdd handleAddSkill={handleAddSkill} skillCategories={skillCategories}/>
          <SkillUpdate skill={updatingSkill} handleUpdateSkill={handleUpdateSkill} handleDeleteSkill={handleDeleteSkill} skillCategories={skillCategories}/>

        </>
      }
    </div>
  )
}

export default AdminSkills