import React, { useContext, useEffect, useState } from 'react'
import { clickOutModal, closeContent } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { GlobalContext } from '../../contexts/GlobalContext'
import { useForm } from 'react-hook-form'
import { TOASTS } from '../../utils/toasts'
import { API_FUNCTIONS } from '../../utils/apiFunctions'

function SkillAdd({handleAddSkill, skillCategories}) {
    const {token} = useContext(GlobalContext)

    const { register, handleSubmit, formState: {errors}, reset} = useForm()

    const addSkill = async(data) => {

        console.log('create skill', data);
        try{
            const response = await API_FUNCTIONS.addSkill(data, token)

            if(response.status === 201){
                TOASTS.success('Skill created successfully')
                handleAddSkill(response.data.data)
                reset()
                closeContent('addSkillModal')
            }else{
                TOASTS.error('Oups, an error occured while creating skill...')
            }
        }catch(e){
            console.error(e)
            TOASTS.error('Oups, an error occured while creating skill...')
        }
    }

  return (
    <div className='modal' id='addSkillModal' onClick={(e) => clickOutModal(e, 'addSkillModal')}>
        <div className='modal__content'>
            <FontAwesomeIcon icon={faXmark} className='modal__content__close' onClick={() => closeContent('addSkillModal')}/>
            <p className='modal__content__title'>Add Skill</p>

            <form onSubmit={handleSubmit(addSkill)} className='modalForm'>
                <label>Skill name (English)</label>
                <input type="text" {...register('skill_en_name', {required: "This field is required"})} required/>

                <label>Nom de la compétence (Français)</label>
                <input type="text" {...register('skill_fr_name', {required: "This field is required"})} required/>
                
                <div className='modalForm__favorite'>
                    <label>Favorite ?</label>
                    <input type="checkbox" {...register('skill_is_favorite')}/>
                </div>

                <label>Skill category</label>
                <select {...register('skill_category_id')}>
                    {
                        skillCategories?.map(cat => 
                            <option key={cat.id} value={cat.id}>{cat.skillCategory_en_name}</option>
                        )

                    }
                </select>

                <button type="submit">Create</button>
            </form>

        </div>
    </div>
  )
}

export default SkillAdd