import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/GlobalContext'
import { TOASTS } from '../../utils/toasts'
import { clickOutModal, closeContent } from '../../utils/functions'
import { API_FUNCTIONS } from '../../utils/apiFunctions'

function UpdateImage({image, handleUpdateImage}) {
    const {token} = useContext(GlobalContext)

        const [enAlt, setEnAlt] = useState('')
        const [frAlt, setFrAlt] = useState('')
    useEffect(() => {
        if(image){
            setEnAlt(image.image_en_alt)
            setFrAlt(image.image_fr_alt)
        }
    }, [image])

    const updateImage = async(e) => {
        e.preventDefault()
        try{
            const response = await API_FUNCTIONS.updateImage({...image, image_en_alt: enAlt, image_fr_alt: frAlt}, token)
            if(response.status === 200){
                TOASTS.success('Images updated successfully')
                handleUpdateImage({...image, image_en_alt: enAlt, image_fr_alt: frAlt})
                setEnAlt('')
                setFrAlt('')
                closeContent('updateImageModal')
            }else{
                TOASTS.error('Oups, an error as occured while updating image...')
            }
        }catch(e){
            TOASTS.error('Oups, an error as occured while updating image...')
        }
    }
  return (
    <div className='modal' id='updateImageModal' onClick={(e) => clickOutModal(e, 'updateImageModal')}>
        <div className='modal__content'>
            <FontAwesomeIcon icon={faXmark} className='modal__content__close' onClick={() => closeContent('updateImageModal')}/>
            <p className='modal__content__title'>Update Image</p>

            <form onSubmit={e => updateImage(e)} className='modalForm'>
                <label>English Alt</label>
                <input type="text" value={enAlt} onChange={e => setEnAlt(e.target.value)} required/>
                
                <label>Frech Alt</label>
                <input type="text" value={frAlt} onChange={e => setFrAlt(e.target.value)} required/>

                <button type="submit">Save</button>
                
            </form>

        </div>
    </div>
  )
}

export default UpdateImage