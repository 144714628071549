import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ScrollEffect from '../containers/ScrollEffect'
import Separator from './Separator'



function Footer() {
  const [t] = useTranslation("global")

  return (
    <ScrollEffect>
      <footer>
        <span>© Copyright 2024 - Yann Ghistelinck</span>
        <Separator/>
        <Link to='/legal'>{t('footer.legal')}</Link>
        {/* <Separator/>
        <span>Designed and built by Yann Ghistelinck</span> */}
      </footer>
  </ScrollEffect>
  )
}

export default Footer